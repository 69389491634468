var product = new Product({});
var customer = new Customer();
var cookieObj = new Cookie();
var shoppingCart = new ShoppingCart();
var wishlist = new WishList();

document.addEventListener("DOMContentLoaded", function(event) {
	initFixedMenu();
	setExtraTopTimer();

	function initFixedMenu() {
		var topMenuOffset = $('.js-header-middle').offset().top,
			topMenuHeight = $('.js-header-middle')[0].clientHeight,
			hasFilterMenu = $('.js-catalog-filter-desktop').length;

		if(hasFilterMenu){
			var filtersMenuOffset = $('.js-catalog-filter-desktop').offset().top - topMenuHeight;

			$(window).on('scroll', function() { scroolFilter(); });scroolFilter();

			function scroolFilter(e) {
				if(window.scrollY>=filtersMenuOffset){
					$('.js-catalog-filter-desktop').addClass('fixed');
				}else{
					$('.js-catalog-filter-desktop').removeClass('fixed');
				}
			}
		}

		$(window).on('scroll', function() { topMenuScrool(); });
		topMenuScrool();

		function topMenuScrool(e) {
			if(window.scrollY>=topMenuOffset){
				$('.js-header-middle').addClass('fixed');
			}else{
				$('.js-header-middle').removeClass('fixed');
			}
		}
	}
	/**
	 * ������ ��������� �
	 */
	function setExtraTopTimer() {
		if ( $('.js-top_popup-timer').length == 0 ) return;
		let $holderElement = $('.js-top_popup-timer');
		let endDateStr = $holderElement.data('end_time');

		let timer = setInterval(function () {
			let now = new Date();
			let date = new Date(endDateStr);
			let msLeft = date - now;
			if (msLeft <= 0) {
				clearInterval(timer);
				$holderElement.hide();
			} else {
				let res = new Date(msLeft);
				let strTimer = '';
				if ( res.getUTCDate() > 1 ) {
					strTimer = res.getUTCDate() - 1 + ' ' + getDays(res.getUTCDate() - 1) + ' ';
				}
				strTimer = strTimer + padNumber(res.getUTCHours(), 2) + ':' + padNumber(res.getUTCMinutes(), 2) + ':' + padNumber(res.getUTCSeconds(), 2);
				$holderElement.html( strTimer );
				$holderElement.show();
			}
		}, 1000);

		function padNumber(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}

		function getDays( cnt ) {
			if ( cnt == 1 ) {
				return '����';
			} else if ( cnt < 5 ) {
				return '���';
			} else {
				return '����';
			}
		}
	}
});
$(window).one('scroll', function () {
	var productsWrap = $('.catalog-container');
	$('img.js-catolog-lazy', productsWrap).each(function() {
		$(this).removeClass('js-catolog-lazy').attr('src', $(this).data('original'));
	});
	$('source.js-catolog-lazy', productsWrap).each(function() {
		$(this).removeClass('js-catolog-lazy').attr('srcset', $(this).data('original'));
	});
	productsWrap.imagesLoaded( function() {
		$('.catalog-item-4 .img-wrapper', productsWrap).matchHeight();
	});
});
const loadScript = function (url) {
    return new Promise(function (resolve, reject) {
        const script = document.createElement('script');
        script.src = url;
        script.addEventListener('load', function () {
            resolve(true);
        });
        document.head.appendChild(script);
    });
};
init_sc_autocomplete();
$('.tooltip').tooltip_init();
$('.phone-11-dig', this.container).phoneMask();
$('.default-ajax-form').ajaxForm( default_ajax_form_options );
$('.default-validate-form').each(function() {
	$(this).validate({
		onChange : $(this).data('validateOnChange') != undefined
	});
});
$('#create-account-form').validate({
	onChange : true,
	valid: function( event, options ) {
		var customer_info = {};
		var translate = {
			'firstname': 'customers_firstname',
			'email_address': 'customers_email_address',
			'telephone': 'customers_telephone',
			'password': 'customers_password',
		};
		$.each($(this).serializeArray(), function(k,v) {
			customer_info[ (translate[v.name] ? translate[v.name] : v.name) ] = v.value;
		});
		customer.create( {
			customer_info: customer_info,
			success: function( msg ) {
				customer.mailCreateSuccess( msg );
				$.magnificPopup.close();
			},
			error: function( msg ) {
				$('#create-account-form .error').find('.validation-description').html( msg.err_mes );
			}
		} );
	},
});
$('#create-account-form').on('submit', function(e) {
	e.preventDefault();
});
$('#login-form').loginForm({
	inputRow: '.box-field__input',
	onLoginFunc: function( msg ) {
		$.magnificPopup.close();
	}
});
$('#login-form2').loginForm({
	inputRow: '.box-field__input',
	onLoginFunc: function( msg ) {
		window.location.href = msg.redirect;
	}
});
$(document).on('click', '.j-show-forgotten-form', function( e ) {
	e.preventDefault();
	$('#forgotten-form2').show();
	$('#login-form2').hide();
});
$(document).on('click', '.j-show-login-form', function( e ) {
	e.preventDefault();
	$('#forgotten-form2').hide();
	$('#login-form2').show();
});
$(document).on('click', '.logout-btn', function( e ) {
	e.preventDefault();
	customer.logoff();
});
var newPassModal = $('#popup-forgottenPass, #forgotten-form2');
$('.main-button', newPassModal).on('click', function(e) {
	e.preventDefault();
	var emailEl = $('input[name=email_address]', newPassModal);
	var email_address = emailEl.val();
	if( email_address.trim().length == 0 ) {
		$('.tip', emailEl.closest('form')).text('������� E-mail');
	} else {
		customer.sendNewPass(
			{
				email_address: email_address
			},
			function( msg ) {
				$('.tip', emailEl.closest('form')).text( msg );
			});
	}
});
$(window).on('load', function(){
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
		$('body').addClass('ios');
	} else{
		$('body').addClass('web');
	};
	$('body').removeClass('loaded'); 
});
/* viewport width */
function viewport(){
	var e = window, 
		a = 'inner';
	if ( !( 'innerWidth' in window ) )
	{
		a = 'client';
		e = document.documentElement || document.body;
	}
	return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
};
/* viewport width */
$(function(){
	$('.catalog-item-4 .title-link h4').matchHeight();
	$('.catalog-container').imagesLoaded( function() {
		$('.catalog-item-4 .img-wrapper').matchHeight();
	});
	/* placeholder*/	   
	$('input, textarea').each(function(){
 		var placeholder = $(this).attr('placeholder');
 		$(this).focus(function(){ $(this).attr('placeholder', '');});
 		$(this).focusout(function(){			 
 			$(this).attr('placeholder', placeholder);  			
 		});
 	});
	/* placeholder*/

	$('.button-nav').click(function(){
		$(this).toggleClass('active'), 
		$('.main-nav-list').slideToggle(); 
		return false;
	});

	initProductsSort();
	initShowSizes();
	initScroll();
	initDropDown();
	initStyler();
	initExpandBlock();
	initMagnific();
	initProductRating();
	initReviews();
	initMasks();
	initZoom();
	initTabs();
	initRange();
	initListing();

	function initProductsSort(){
		$('.js-close-dropdown').click(function(){
			$('.js-dropDown-trigger',$(this).closest('.js-dropDown')).trigger('click');
		});
	}

	function initShowSizes(){
		var hasSizesForm = $('#index-product-show-sizes').length;

		if(!hasSizesForm){
			return false;
		}

		$('.js-load-size-table').click(function(){
			var isLoading = $('.j-index-product-size-table').attr('data-loading'),
				loading = $('.j-index-product-size-table').attr('data-loading-now'),
				get = {ajax:3},
				action = $('#index-product-show-sizes').attr('action'),
				popupLoading = $('.j-index-product-size-table .js-loading');

			if(isLoading || loading){
				return true;
			}

			$('.j-index-product-size-table').attr('data-loading-now',1);
			popupLoading.fadeIn(300);
			$('#index-product-show-sizes').serializeArray().forEach(function(data){
				get[data.name] = data.value;
			});
			$.get(action,get).done(function(data){
				var html = '';

				if(typeof data.data != "object"){
					error();
					return true;
				}
				for(var block in data.data){
					html = data.data[block];
					break;
				}
				if(html) {
					$('.j-index-product-size-table').attr('data-loading',1);
					$('.j-index-product-size-table .js-output-content').html(html);
				}else{
					error()
				}
			}).fail(function(){
				error()
			}).always(function() {
				$('.j-index-product-size-table').attr('data-loading-now',0);
				popupLoading.fadeOut(300);
				$('body').addClass('overlay');
			});

			function error(){
				$('.j-index-product-size-table .js-error').fadeIn(300);
				setTimeout(function(){
					$('.j-index-product-size-table .js-error').fadeOut(300);
				},5000);
			}
		});
	}

	if( $('.popupSearchMap-section').length ) {
		if($('[data-id=ymaps]').length>0){
			loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
				$('[data-id=ymaps]').remove();
				initInfoShops( $('.popupSearchMap-section') );
			});
		}
		else{
			initInfoShops( $('.popupSearchMap-section') );
		}
	}

	if($('.js-header-functionals__item:not(.js-header-cart)').length){
		$('.js-header-functionals__item:not(.js-header-cart)').mouseenter(function(){
			$(this).find('.header-functionals__dropDown').slideDown();
		});
		$('.js-header-functionals__item:not(.js-header-cart)').mouseleave(function(){
			$(this).find('.header-functionals__dropDown').hide();
		});

		$('.js-header-functionals__item:not(.js-header-cart)').click(function(){
			$('#mobile-menu-switcher').prop('checked', false);
		});
		$('.js-header-functionals__item:not(.js-header-cart) .hide-dropDown').click(function(){
			$(this).parents('.header-functionals__dropDown').slideUp();
		});
	}

	if($('.js-subNav').length){
		$('.header-nav__list>li').mouseenter(function(){
			$(this).find('.header-nav__subNav').slideDown();
			$('main, footer').css('opacity', '0.3');
		});
		$('.header-nav__list>li').mouseleave(function(){
			$(this).find('.header-nav__subNav').hide();
			$('main, footer').css('opacity', '');
		});
	}

	if($('.js-header-slider').length){
		$('.js-header-slider').slick({
			dots: false,
			arrows: true,
			infinite: true,
			speed: 300,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
	}

	if($('.j-banner-slider').length){
		$('.j-banner-slider').slick({
			dots: false,
			arrows: true,
			infinite: true,
			speed: 300,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
					}
				}
			]
		});
	}

	if($('.j-mainpage-categories-slider').length){
		$('.j-mainpage-categories-slider').slick({
			dots: false,
			arrows: true,
			infinite: true,
			speed: 1000,
			autoplay: false,
			slidesToShow: 6,
			slidesToScroll: 6,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						speed: 300,
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						speed: 300,
						arrows: false,
					}
				}
			]
		});
		$('.j-mainpage-categories-slider .text').matchHeight();
	}

	if($('.list-radio').length || $('.list-checkbox').length){
		$('.list-radio__label').click(function(){			
			var radioButton = $(this).find('div.jq-radio');
			$('.list-radio__label').removeClass('checked');
			if(radioButton.hasClass('checked')){
				$(this).addClass('checked');
			} else {
				$(this).remove('checked');
			}
		});

		$('.list-checkbox__label').click(function(){
			var checkBoxButton = $(this).find('div.jq-checkbox');
			if(checkBoxButton.hasClass('checked')){
				$(this).addClass('checked');
			} else {
				$(this).removeClass('checked');
			}
		});
	}
	if( $('section.product').length ) {
		cookieObj.addViewedProduct( $('section.product .j-add-product-form').data('productsId').toString() );
	}

	$(document).on('change', 'select.j-select-size-type', function() {
		var form = $(this).closest('form');
		var activeNew = $('.size-type-list .size-type-' + $(this).val(), form);
		var otherLayers = activeNew.siblings();
		activeNew.addClass('active');
		otherLayers.removeClass('active');
		$('select', activeNew).prop('disabled', false).trigger('refresh');
		$('select', otherLayers).prop('disabled', true).trigger('refresh');
		cookieObj.write('proskater_cookie', 'size-type-'+$(this).data('sizetype'), $(this).val());
	});

	$(document).on('click', '.j-add-product', function(e) {
		e.preventDefault();
		var form = $('form.j-add-product-form[data-products-id=' + $(this).data('productsId') + ']');
		var checkedFlag = true;
		if( $('input[name="id[1]"]:radio', form).length && $('input[name="id[1]"]:checked', form).length == 0 ) {
			checkedFlag = false;
		}
		if( $('input[name="id[1]"]:hidden', form).length && $('input[name="id[1]"]:hidden', form).val() == '' ) {
			checkedFlag = false;
		}
		if( $('select:not(:disabled)[name="id[1]"]', form).length && $('select:not(:disabled)[name="id[1]"]', form).val() == '' ) {
			checkedFlag = false;
		}
		if( !checkedFlag ) {
			var rowForDrop = $(this).closest('.addToBag-container');
			if( rowForDrop.length ) {
				$('.drop-size', rowForDrop).show();
				$('.drop-size-bg', rowForDrop).show();
			}
		} else {
			if( $(this).hasClass('go-shopping-cart') ) {
				return false;
			}
			$('.drop-size', rowForDrop).hide();
			// $(this).hide();
			// $('#go-to-basket').css('display', 'block');
			var formData = form.serializeArray();
			shoppingCart.addProducts({
				product_info: form.serializeArray()
			}, {
				checkDublicate: false
			});
			$(this).closest('.product').find('.product-availability span').text('��������� � �������');
			$(this).addClass('mfp-hide').next('.j-add-product-done').removeClass('mfp-hide').next('.j-same-products-link').addClass('mfp-hide');

			// $(this).removeClass('j-add-product');
			// $(this).addClass('go-shopping-cart');
			// $(this).text('������� � �������');


		}
	});

	$('.header-cart__list').on('click', '.j-cart-delete', function(e) {
		e.preventDefault();
		shoppingCart.deleteProduct({
			products_id: $(this).closest('li').data('productsId')
		});
	});

	$(document).on('click', '.js-quick-see', function(e) {
		e.preventDefault();
		$('.catalog-quickView').slideUp(400, function(){$(this).remove();}).empty();
		var productWrap = $(this).closest('.js-fast-view');
		var listWrap = productWrap.parent();
		var listItems = $('.js-fast-view', listWrap);
		var fastViewAfterIndex = listItems.index( productWrap ) + 4 - listItems.index( productWrap ) % 4 - 1;
		if ( $(this).closest('.wishlist-page').length ) {
			fastViewAfterIndex = listItems.index( productWrap );
		}
		fastViewAfterIndex = Math.min( fastViewAfterIndex, listItems.length - 1 );
		var fastViewWrap = listItems.eq(fastViewAfterIndex).after('<div class="catalog-quickView" style="display:none"></div>').next();
		$.ajax({
			url: $(this).attr('href'),
			data: { action: 'fast_view', 'ajax': 3 },
			context: this,
			success: function(msg) {
				fastViewWrap.addClass('active').html( msg.html );
				$(this).addClass('opened');
				initQuickViewScripts();
				fastViewWrap.slideDown(400, function() {
					$('.js-product-photos__slider').slick('resize');
					$('html, body').animate({ scrollTop: $(fastViewWrap).offset().top - 50 });
					if ( window.PROSKTR.content == 'product_info' ) {
						var height_footer = $('footer').height();
						var height_bottomSections = $('.bottomSections').height();
						var height_bottom = height_footer + height_bottomSections;
						var viewport_wid = viewport().width;
						if (viewport_wid >= 768) {
							destroySticky();
							initSticky(height_bottom);
						}
					}
				});
			}
		})
	});
	$(document).on('click', '.catalog-quickView .dropDown-close', function(){
		$(this).parent().slideUp(400, function(){$(this).remove();});
	});
	// if($('.catalog-card .quickView-button').length){
	// 	$('.catalog-card .quickView-button').click(function(e) {
	// 		var thisButton = $(this);
	// 		var catalogContainer = thisButton.parents('.catalog-container');
	// 		var quickView = catalogContainer.find('.catalog-quickView');

	// 		$('.catalog-quickView').slideUp().empty();

	// 		e.preventDefault;
	// 		$.ajax({
	// 			//url: 'templates/tpl-quick-view.html'
	// 		}).done(function() {
	// 			quickView.addClass('active').on('load', "templates/tpl-quick-view.html");
	// 			thisButton.addClass('opened');
	// 		});

	// 		setTimeout(function(){
	// 			initQuickViewScripts();
	// 			quickView.slideDown();

	// 			$('.catalog-quickView .dropDown-close').click(function(){
	// 				$(this).parent().slideUp();
	// 			});
	// 		}, 500);

	// 		return false;
	// 	});
	// }

	$(document).on('click', '.j-wishlist-add', function(e) {
		e.preventDefault();
		var el = this;
		if( $(this).data('wishlist') ) {
			wishlist.remove( $(this).data('products_id'), function() {
				$(el).data('wishlist', 0);
				$(el).removeClass('active');
				if( false ) { // ������� �� ������ �� �������� wishlist
					var ul = $(el).closest('.wishlist-list');
					var $ul_viewed = $(el).closest('.viewed_list');
					if( !$ul_viewed.length && $('.viewed_list').length > 0 ) {
						$('.viewed_list').find('[data-products_id="'+$(el).data('products_id')+'"]').closest('.product-card').find('.j-wishlist-add').removeClass('zmdi-star').addClass('zmdi-star-outline').data('wishlist', 0);
					}
					if( ul.length || $ul_viewed.length ) {
						if( ul.length) {
							var li = $(el).closest('.product-card');
						}
						if($ul_viewed.length){
							var li = $('.wishlist-list').find('[data-id="'+$(el).data('products_id')+'"]').closest('.product-card');
						}
						$(li).animate({
							opacity: [ 0, "linear" ],
						}, 300, "linear", function() {
							$(this).animate({
								height: [ 0, "linear" ],
								width: [ 0, "linear" ]
							}, 300, "linear", function() {
								$(this).remove();
								if( ul.find('.product-card').length == 0 ) {
									ul.next().show();
								}
							});
						});
					}
				}
			} );
		} else {
			wishlist.add( $(this).data('products_id'), function(msg) {
				var addedWindow = $('.content .addedProduct');
				if( addedWindow.length ) {
					addedWindow.addClass('active').html( $.render[ 'wishlist_added' ].render( msg.addedProducts, { currencies: window.PROSKTR.currencies } ) );
					addedWindow.slideDown();
				}
				$(el).data('wishlist', 1);
				$(el).addClass('active');
				if( false ) { // ��������� � ������ �� �������� wishlist
					var $ul_viewed = $(el).closest('.viewed_list');
					var $ul = $('.wishlist-list');
					if( $ul_viewed.length ) {
						var $li = $(el).closest('.product-card').clone().css({'display' : 'none'});
						$ul.prepend($li);
						$li.fadeIn(600).find('.j-wishlist-add').removeClass('zmdi-star-outline').addClass('zmdi-star').data('wishlist', 1);
						$ul.next().hide();
					}
				}
			} );
		}
	});
	$(document).on('click', '.addedProduct .dropDown-close, .addedProduct .dropDownClose', function(e) {
		e.preventDefault();
		var addedWindow = $(this).parents('.addedProduct');
		addedWindow.slideUp();
		addedWindow.next('.addedProductBg').removeClass('active');
	});
	$(document).on('click', '.addedProductBg', function(e) {
		e.preventDefault();
		$(this).removeClass('active').prev('.addedProduct').slideUp();
	});

	$(document).on('change', '.catalog-card__sizes-wrapper', function(e) {
		shoppingCart.addProducts({
			product_info: $(this).serializeArray(),
		}, {
			checkDublicate: false
		});
	});

	// $('.addToWhishList, .addToWhishList-form__button').click(function(e) {
	// 	var thisButton = $(this);
	// 	var addedWindow = $('.content').find('.addedProduct');
	// 	thisButton.parent().addClass('active');

	// 	$('.addedProduct').slideUp().empty();

	// 	e.preventDefault;
	// 	// $.ajax({
	// 	// 	//url: 'templates/tpl-quick-view.html'
	// 	// }).done(function() {
	// 	// 	addedWindow.addClass('active').html('');
	// 	// 	thisButton.addClass('opened');
	// 	// });

	// 	setTimeout(function(){
	// 		addedWindow.slideDown();

	// 		$('.addedProduct .dropDown-close').click(function(){
	// 			$(this).parents('.addedProduct').slideUp();
	// 		});
	// 	}, 500);

	// 	return false;
	// });

	if($('.js-catalog-card').length){
		if ( viewport().width >= 768 ) {
			$(document).on('mouseenter', '.js-catalog-card', function(){
				var catalogItem = $(this).closest('.catalog-item-4');
				$(catalogItem).css('height', $(catalogItem).height() + 'px');
				$(this).addClass('active');
				$(this).find('.img-wrapper .quickView-button').show();
				$(this).find('.catalog-card__saleInfo, .catalog-card__sizes-wrapper').show();
			});
			$(document).on('mouseleave', '.js-catalog-card', function(){
				var catalogItem = $(this).closest('.catalog-item-4');
				$(catalogItem).css('height', '');
				$(this).removeClass('active');
				$(this).find('.img-wrapper .quickView-button').hide();
				$(this).find('.catalog-card__saleInfo, .catalog-card__sizes-wrapper').hide();
			});
			if($('.js-catalog__sizes').length){
				$('.js-catalog__sizes').find('.disabled a').attr('href', 'javascript:void(0);')
			}
		}
	}
	function changeIterator( el ) {
		var iterator = el.data('iterator');
		var startIterator = el.data('start-iterator');
		if ( iterator < el.data('img_count') ) {
			iterator++;
		} else {
			iterator = 1;
		}
		el.data('iterator', iterator);
		el.attr('src', el.data('firstSrc').replace('_' + startIterator + '.jpg', '_' + iterator + '.jpg') );
	}
	$(".no-touch .catalog-container.ab-hover-1").on({
		mouseenter: function () {
			var img = $('img', this);
			var src = img.attr('src')
			var startIterator = src.match(/_(\d+)\.jpg/i)[1];
			img.data('firstSrc', src);
			img.data('start-iterator', startIterator);
			img.data('iterator', startIterator);
			var currentClass = 'hover_' + Math.random() * (10 - 0) + 0;
			img.addClass( currentClass );
			changeIterator( img );
			var animation = setInterval( function(){
				if ( !img.hasClass( currentClass ) ) {
					clearInterval( animation );
					return false;
				}
				changeIterator( img );
			}, 1200);
		},
		mouseleave: function () {
			var img = $('img', this);
			if(img.data('firstSrc')) {
				img.attr('src', img.data('firstSrc'));
			}
			img.data('iterator', img.data('start-iterator') );
			img.removeClassWild("hover_*");
		}
	}, '.js-catalog-card .img-wrapper');

	if($('.main-button, .little-button, .blank-button').length){
		if($('.main-button, .little-button, .blank-button').hasClass('disabled')){
			$('.main-button, .little-button, .blank-button').attr('href', 'javascript:void(0);');
		}
	}

	if($('.product-photos__item').length){
		$('.js-zoom-btn').click(function(){
			$(this).toggleClass('active');
			$(this).parent().find('.img-wrapper-preview').toggleClass('active');
		});

		$('.product-photos__item').mouseleave(function(){
			$(this).find('.js-zoom-btn').removeClass('active');
			$(this).find('.img-wrapper-preview').removeClass('active');
		});
	}

	if($('.cart-checkout__item').length){
		$('.cart-checkout__item').on('change', function(){
			if($(this).find('#onlineCard').parent().hasClass('checked')){
				$(this).find('.order-confirm').slideDown();
			} else {
				$(this).find('.order-confirm').slideUp();
			}
		});
	}

	if($('.js-mark-line').length){
		var markLineArr = new Array();
		markLineArr = $('.js-mark-line');
		
		for(var i = 0; i < markLineArr.length; i++){
			if(markLineArr.eq(i).find('.checked') == true){
				markLineArr.eq(i).find('.message').html('');
			} else {
				markLineArr.eq(i).find('.message').html('������ �� �������');
			}
		}

		$('.js-mark-line li').on('click', function(){				
			$(this).parents('.js-mark-line').find('.message').html('');
		});
	}

	if($('.header-navMobile__functionals').length){
		$('.header-navMobile__functionals .trigger').click(function(){
			$(this).toggleClass('active');
			$(this).siblings('.functionals-subList').toggleClass('active').slideToggle();
		})
	}

	if($('.header-searchTrigger').length){
		$('.header-searchTrigger').click(function(){
			$('#mobile-menu-switcher').prop('checked', false);
			$(this).toggleClass('active');
			$(this).parents('.header-middle').find('.js-search').toggleClass('active').toggleClass('opened').slideToggle();
		});
	}

	if($('.catalog-filter__mobile').length){

		$('.filter-trigger').click(function(){
			$(this).parents('.catalog-filter').find('.catalog-filter__mobile').fadeIn();
		});

		$('.catalog-filter__mobile .j-close-m-filters').click(function(e){
			e.preventDefault();
			$(this).parents('.catalog-filter__mobile').fadeOut();
		});
	}

	$("#popup-cancelOrder-form").submit(function(e) {
		e.preventDefault;
		$.ajax({

		}).done(function() {
			
			setTimeout(function() {
				$.magnificPopup.close();
				$.magnificPopup.open({
					items: {
						src: '#popup-alert',				
					},
					
					fixedBgPos: true,
					fixedContentPos: true,
					showCloseBtn: true,
					closeBtnInside: true,
					removalDelay: 0,
					preloader: true,
					type:"inline",
					mainClass: 'mfp-fade mfp-s-loading',
					galery: {enabled: true},		
				});
			}, 200);
		});
		$('.js-close-popup').click(function(){
		$.magnificPopup.close();
	});
		return false;
	});

	// $("#addressSearch-form, #geolocation-form").submit(function(e) {
	// 	e.preventDefault;
	// 	$.ajax({

	// 	}).done(function() {
			
	// 		setTimeout(function() {
	// 			$.magnificPopup.close();
	// 			$.magnificPopup.open({
	// 				items: {
	// 					src: '#popup-searchMapResults',
	// 				},

	// 				fixedBgPos: true,
	// 				fixedContentPos: true,
	// 				showCloseBtn: true,
	// 				closeBtnInside: true,
	// 				removalDelay: 0,
	// 				preloader: true,
	// 				type:"inline",
	// 				mainClass: 'mfp-fade mfp-s-loading',
	// 				gallery: {enabled: true},

	// 				callbacks: {
	// 					open: function() {
	// 						initScroll();
	// 					},
	// 					close: function(){
	// 						destroyScroll();
	// 					}
	// 				}
	// 			});
	// 		}, 200);
	// 	});
	// 	$('.js-close-popup').click(function(){
	// 		$.magnificPopup.close();
	// 	});
	// 	return false;
	// });

	$('.js-close-popup').click(function(){
		$.magnificPopup.close();
	});

	if($('.js-btn-profileNav').length){
		var textActive = $('.js-btn-profileNav').next('.profile-nav__list').find('li.active a').text();
		$('.js-btn-profileNav').text(textActive);

		$('.js-btn-profileNav').click(function(){
			$(this).toggleClass('active').next('.profile-nav__list').slideToggle();
		});
	}

	if($('.delivery-item__trigger').length){
		$('.delivery-item__trigger').click(function(){
			$(this).toggleClass('active').next('.delivery-item__container').slideToggle();
		});
	}

	if($('.return-item').length){
		$('.return-item__head').click(function(){
			$(this).toggleClass('active').next('.return-item__body').slideToggle();
		});
	}

	if($('.js-map').length){
		var mapItemArr = new Array();
		mapItemArr = $('.js-map');

		var mapID
		
		for(var i = 0; i < mapItemArr.length; i++){
			mapID = mapItemArr.eq(i).attr('id');
			/*if($('[data-id=ymaps]').length>0){
				loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
					$('[data-id=ymaps]').remove();
					initMap(mapID);
				});
			}
			else{*/
				initMap(mapID);
			//}
		}
	}
	
	/* components */
	
	

});

window.forFixedHeaderVars = {

}
function FixedHeader() {
	this.elements = {};
	this.vars = {
		lastScrollTop: $(window).scrollTop(),
		scrolledY: 0, // �� ������� ���������� �����
	};
	this.flags = {
		fixedHeader: false,
		fixedFilters: false,
		};
	this.elements.headerTop = $('.header-top');
	this.elements.headerMiddle = $('.header-middle');
	// this.elements.headerNav = $('.header-nav');
	this.elements.catalog = $('.catalog-body');
	this.elements.sctickyFilter = $('#catalog-filter-desktop');
	this.elements.sctickyFilterMobile = $('#catalog-filter-mobile');
	// this.elements.sortBlock = $('.fsortBlock');
	this.elements.sideCategories = $('.side-categories');
	this.elements.stickySideCategories = $('.side-categories > ul');
	this.vars.initialCatalogPaddingTop = this.elements.catalog.css('padding-top');
	this.updateVars();
};
FixedHeader.prototype.updateVars = function() {
	this.vars.headerTop_height = this.elements.headerTop.outerHeight();
	this.vars.headerMiddle_height = this.elements.headerMiddle.outerHeight();
	// this.vars.headerNav_height = this.elements.headerNav.outerHeight();

	this.vars.sctickyFilter_height = this.elements.sctickyFilter.outerHeight();
	this.vars.sctickyFilter_Y = this.elements.sctickyFilter.position().top;
	this.vars.sctickyFilterMobile_height = this.elements.sctickyFilterMobile.outerHeight();
	this.vars.sctickyFilterMobile_Y = this.elements.sctickyFilterMobile.position().top;
	this.vars.viewport_wid = viewport().width;
	this.vars.viewport_height = viewport().height;
};
FixedHeader.prototype.checkScroll = function( e ) {
	if( this.vars.viewport_wid < 1200 ) {
		// return;
	}
	var st = $(window).scrollTop();
	if( st > this.vars.lastScrollTop ) { // ���� ������������ ����
		this.unsetFixedHeader();
		this.unsetFixedFilters();
		this.unsetFixedFiltersMobile();
		this.vars.scrolledY = 0;
	} else { // ���� ������������ �����
		this.vars.scrolledY+= this.vars.lastScrollTop - st;
		if( st > this.vars.headerTop_height ){
			if( this.vars.scrolledY > 400 ) { // ���� ����������� ������ ��� �� 400px - ��������� �����
				this.setFixedHeader();
			}
		} else {
			this.unsetFixedHeader();
		}

		if( st > this.vars.sctickyFilter_Y - this.vars.headerTop_height/* - this.vars.headerNav_height*/ ){
			if( this.vars.scrolledY > 400 ) { // ���� ����������� ������ ��� �� 400px - ��������� �����
				this.setFixedFilters();
			}
		} else {
			this.unsetFixedFilters();
		}
		if( st > this.vars.sctickyFilterMobile_Y - this.vars.headerTop_height ){
			if( this.vars.scrolledY > 400 ) { // ���� ����������� ������ ��� �� 400px - ��������� �����
				this.setFixedFiltersMobile();
			}
		} else {
			this.unsetFixedFiltersMobile();
		}
	}
	this.setFixedSideCategories( this.getFixedSideCategories() );
	this.vars.lastScrollTop = st;
};
FixedHeader.prototype.checkResize = function() {
	this.updateVars();
	if( this.vars.viewport_wid < 1200 ) {
		// this.unsetFixedHeader();
		this.unsetFixedFilters();
	} else {
		this.unsetFixedFiltersMobile();
	}
}
FixedHeader.prototype.setFixedHeader = function() {
	if( this.flags.fixedHeader ) {
		return;
	}
	this.elements.headerMiddle.addClass('window-scrolling');
	// this.elements.headerNav.addClass('window-scrolling');
	// this.elements.headerNav.css({'top': this.vars.headerMiddle_height + 'px'});
	this.elements.headerTop.css({'margin-bottom': (this.vars.headerMiddle_height/* + this.vars.headerNav_height*/) + 'px'});
	this.flags.fixedHeader = true;
};
FixedHeader.prototype.unsetFixedHeader = function() {
	if( !this.flags.fixedHeader ) {
		return;
	}
	this.elements.headerMiddle.removeClass('window-scrolling');
	// this.elements.headerNav.removeClass('window-scrolling');
	// this.elements.headerNav.css({'top': 0});
	this.elements.headerTop.css({'margin-bottom': 0});
	this.flags.fixedHeader = false;
};
FixedHeader.prototype.setFixedFilters = function() {
	if( this.flags.fixedFilters ) {
		return;
	}
	this.elements.sctickyFilter.addClass('window-scrolling');
	this.elements.sctickyFilter.css({'top': (this.vars.headerMiddle_height/* + this.vars.headerNav_height*/) + 'px'});
	this.elements.catalog.css({'padding-top': (this.vars.sctickyFilter_height + 35 + this.vars.initialCatalogPaddingTop) + 'px'});
	// this.elements.sortBlock.addClass('window-scrolling');
	// this.elements.sortBlock.css({'top':this.vars.headerMiddle_height + 'px'});
	this.flags.fixedFilters = true;
};
FixedHeader.prototype.unsetFixedFilters = function() {
	if( !this.flags.fixedFilters ) {
		return;
	}
	this.elements.sctickyFilter.removeClass('window-scrolling');
	this.elements.sctickyFilter.css({'top': 0});
	this.elements.catalog.css({'padding-top': this.vars.initialCatalogPaddingTop});
	// this.elements.sortBlock.removeClass('window-scrolling');
	// this.elements.sortBlock.css({'top': 0});
	this.flags.fixedFilters = false;
};
FixedHeader.prototype.setFixedFiltersMobile = function() {
	if( this.flags.fixedFiltersMobile ) {
		return;
	}
	this.elements.sctickyFilterMobile.addClass('window-scrolling');
	this.elements.sctickyFilterMobile.css({'top': (this.vars.headerMiddle_height) + 'px'});
	this.elements.catalog.css({'padding-top': (this.vars.sctickyFilterMobile_height + 35 + this.vars.initialCatalogPaddingTop) + 'px'});
	// this.elements.sortBlock.addClass('window-scrolling');
	// this.elements.sortBlock.css({'top':this.vars.headerMiddle_height + 'px'});
	this.flags.fixedFiltersMobile = true;
};
FixedHeader.prototype.unsetFixedFiltersMobile = function() {
	if( !this.flags.fixedFiltersMobile ) {
		return;
	}
	this.elements.sctickyFilterMobile.removeClass('window-scrolling');
	this.elements.sctickyFilterMobile.css({'top': 0});
	this.elements.catalog.css({'padding-top': this.vars.initialCatalogPaddingTop});
	// this.elements.sortBlock.removeClass('window-scrolling');
	// this.elements.sortBlock.css({'top': 0});
	this.flags.fixedFiltersMobile = false;
};
FixedHeader.prototype.getFixedSideCategories = function() {
	if ( this.vars.viewport_wid <= 1199 ) {
		return 0;
	}
	var st = $(window).scrollTop();
	var moreScroll = this.flags.fixedHeader ?  this.elements.sctickyFilter.outerHeight() + 40 : 0;
	if ( st - this.elements.sideCategories.offset().top + moreScroll >= -20 ) {
		if ( st + this.elements.stickySideCategories.outerHeight() + 20 >= this.elements.sideCategories.offset().top + this.elements.sideCategories.outerHeight() ) {
			return 3;
		} else {
			if ( this.flags.fixedHeader ) {
				return 2;
			} else {
				return 1;
			}
		}
	} else {
		return 0;
	}
}
FixedHeader.prototype.setFixedSideCategories = function( state ) {
	if ( this.vars.viewport_wid <= 1199 ) {
		return;
	}
	if ( state == 1 ) {
		this.elements.stickySideCategories.addClass('fixed');
		this.elements.stickySideCategories.removeClass('bottom');
		this.elements.stickySideCategories.css('top', '135px');
	} else if ( state == 2 ) {
		this.elements.stickySideCategories.addClass('fixed');
		this.elements.stickySideCategories.removeClass('bottom');
		var top = this.elements.headerTop.outerHeight() + this.elements.headerMiddle.outerHeight()/* + this.elements.headerNav.outerHeight()*/ + this.elements.sctickyFilter.outerHeight() - 10;
		this.elements.stickySideCategories.css('top', top + 'px');
	} else if ( state == 3 ) {
		this.elements.stickySideCategories.addClass('bottom').removeClass('fixed').css('top', '');
	} else {
		this.elements.stickySideCategories.removeClass('fixed');
	}
}

if( $('.catalog').length ) {
	var fixedHeader = new FixedHeader;
	$(window).on('scroll', function( e ) {
		fixedHeader.checkScroll( e );
	});
	$(window).on('resize', function( e ) {
		fixedHeader.checkResize( e );
	});
}
$('.footer-item.nav-block').on('click', '.footer-title', function(){
	if ( viewport().width <= 768 || $('.footer.light-footer').length ) {
		$(this).siblings('.footer-nav-list').slideToggle();
	}
});

$('.js-mobile-navBtn').click(function(){
	if ( viewport().width <= 991 && $('.header-searchTrigger').hasClass('active') ) {
		$('.header-searchTrigger').removeClass('active');
	}
});
var handler = function(){
	var height_footer = $('footer').height();	
	var height_header = $('header').height();
	var height_bottomSections = $('.bottomSections').height();
	var height_bottom = height_footer + height_bottomSections;
	//$('.content').css({'padding-bottom':height_footer+40, 'padding-top':height_header+40});
	var viewport_wid = viewport().width;
	var viewport_height = viewport().height;

	if (viewport_wid >= 1200) {

		// $(window).scroll(function(){

			// if($(this).scrollTop() > $('.header').outerHeight() ){
			// 	$('.js-navScroll').addClass('scrolling');
			// 	$('.header').css('margin-bottom', $('.js-navScroll').outerHeight());
			// }
			// else{
			// 	$('.js-navScroll').removeClass('scrolling')
			// 	$('.header').css('margin-bottom', '0');
			// }
		// });
	}

	if(viewport_wid <= 1199){
		$('.js-header-functionals__item:not(.header-cart)').find('a.icon-image').attr('href', 'javascript:void(0);');
	}

	if (viewport_wid >= 768) {
		initSticky(height_bottom);

		if($('.expandBlock').length){
			$('.expandBlock .title, .comment-btn').click(function(){
				if ( !$(this).hasClass('product_infoPages') ) {
					if($('.js-sticky').length){
						destroySticky();
						var height_footer = $('footer').height() + $('.bottomSections').height();
						setTimeout(function(){
							initSticky(height_footer);
						}, 500)
					}
				}
			});
		}

		if($('.return-item__head').length){
			$('.return-item__head').removeClass('mobile');
		}
	} else {
		destroySticky();
	}

	if(viewport_wid < 768) {
		if($('.js-photos-slider').not('.slick-initialized').length){
			$('.js-photos-slider').slick({
				dots: true,
				arrows: false,
				infinite: false,
				speed: 300,
				slidesToShow: 2,
				slidesToScroll: 2,
				responsive: [
					{
					  breakpoint: 576,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					  }
					},
					{
					  breakpoint: 600,
					  settings: "unslick"
					}				
				]
			}).resize();
		}

		if($('.return-item__head').length){
			$('.return-item__head').addClass('mobile');
		}
	}

}
$(window).on('load', handler);
$(window).resize(handler);
$(window).scroll(checkFixedAddButtomProduct);
$(window).resize(checkFixedAddButtomProduct);


function initDropDown(){
	if($('.js-dropDown').length){
		$(document).on('click', '.catalog-filter__item.active .dropDown-button .icon', function(e) {
			var thisCatalogFilter = $(this).parents('.catalog-filter__item');
			removeCatalogFilterCondition(thisCatalogFilter);
			var _this = this;
			if ( $(this).closest('.fake-cat-form').length ) {
				var filter = $(this).parents('.catalog-filter__item').data('filtr_id');
				var filterEl = $('#filtr-form').find('.catalog-filter__item[data-filtr_id="' + filter + '"]');
				_this = $('.dropDown-button .icon', filterEl);
				listing.clearFiltr( _this );
			}
			listing.clearFiltr( this );
			e.stopPropagation();
		});
		$('.js-open-mobile-submenu').click( function(e){
			var inputSwitcher = $(this).parent().find('.js-subgroups-modal-menu-switcher'),
				inputSwitcherChecked = $(inputSwitcher).prop('checked');

			$(inputSwitcher).prop('checked', !inputSwitcherChecked);
			e.stopPropagation();
		});
		$(document).on('click', '.js-dropDown .dropDown-trigger', function(e){
			if($(this).hasClass('active')){
				$(this).removeClass('active');
				$(this).next('.dropDown-content').removeClass('dropDown-opened').slideUp();
			} else {
				$('.dropDown-content').slideUp();
				$('.dropDown-content').removeClass('dropDown-opened');
				$('.js-dropDown .dropDown-trigger').removeClass('active');
				$(this).addClass('active');
				$(this).next('.dropDown-content').addClass('dropDown-opened').slideDown();
			}
		});

		$(document).on('click', '.js-dropDown .dropDown-close', function(){
			$(this).parents('.js-dropDown').find('.dropDown-trigger').removeClass('active');
			$(this).parents('.dropDown-content').removeClass('dropDown-opened').slideUp();
		});
	}
}

function initSearch(){
	if($('.js-search').length){
		$('.js-search .reset-button').html('<img src="/templates/iridato/img/icons/icon-search.svg">');
		$('.js-search').on('input', function(){
			if($('.js-search input').val() != ''){
				$(this).find('.reset-button').html('<i class="icon icon-close active"></i>');
				$(this).find('.header-search__dropDown').slideDown().addClass('opened');
				$(this).find('.search-field__input').addClass('filled');
			} else {
				$(this).find('.reset-button').html('<img src="/templates/iridato/img/icons/icon-search.svg">');
				$(this).find('.header-search__dropDown').slideUp().removeClass('opened');
				$(this).find('.search-field__input').removeClass('filled');
			}
		});

		$('.js-search').on('reset', function(){
			$(this).find('.reset-button').html('<img src="/templates/iridato/img/icons/icon-search.svg">');
			$(this).find('.header-search__dropDown').slideUp().removeClass('opened');
			$(this).find('.search-field__input').removeClass('filled');
		});
	}
}

function initStyler(){
	if($('.js-styled').length) {
		$('.js-styled').styler();
	};
}

function initExpandBlock(){
	if($('.expandBlock').length){
		$('.expandBlock-item .title').click(function(){
			if ( $(this).hasClass('product_infoPages') ) {
				$.magnificPopup.open({
					items: {
						src: '#infoPagesPopup',
						type: 'inline',
					},
				});
			} else {
				if($(this).parents('.expandBlock-item').hasClass('opened')){
					$(this).parents('.expandBlock-item').removeClass('opened');
					$(this).parents('.expandBlock-item').find('.body').slideUp();
				} else {
					$('.expandBlock-item').removeClass('opened');
					$('.expandBlock-item').find('.body').slideUp();
					$(this).parents('.expandBlock-item').addClass('opened');
					$(this).parents('.expandBlock-item').find('.body').slideDown();
				}
			}
		});
		$('.expandBlock-item .sub-title').click(function(){
			if($(this).parents('.expandBlock-sub-item').hasClass('opened')){
				$(this).parents('.expandBlock-sub-item').removeClass('opened');
				$(this).parents('.expandBlock-sub-item').find('.sub-body').slideUp();
			} else {
				$('.expandBlock-sub-item').removeClass('opened');
				$('.expandBlock-sub-item').find('.sub-body').slideUp();
				$(this).parents('.expandBlock-sub-item').addClass('opened');
				$(this).parents('.expandBlock-sub-item').find('.sub-body').slideDown();
			}
		});
	}
}


function initMagnific(){
	if($('.js-popup').length){
		$('.js-popup').magnificPopup({
			fixedBgPos: true,
			fixedContentPos: true,
			showCloseBtn: true,
			closeBtnInside: true,
			removalDelay: 0,
			preloader: true,
			type:"inline",
			mainClass: 'mfp-fade mfp-s-loading',
			galery: {enabled: true},
			callbacks: {
				open: function(){
					initScroll();
				},

				close: function(){
					destroyScroll();
				}
			}
		});
	}
	$('.js-popup-searchMap').magnificPopup({
		fixedBgPos: true,
		fixedContentPos: true,
		showCloseBtn: true,
		closeBtnInside: true,
		removalDelay: 0,
		preloader: true,
		type:"inline",
		mainClass: 'mfp-fade mfp-s-loading',
		galery: {enabled: true},
		items: {
			src: '#popup-searchMap',
			type: 'inline'
		},
		callbacks: {
			open: function(){
				// initScroll();
				/*if($('[data-id=ymaps]').length>0){
					loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
						$('[data-id=ymaps]').remove();
						initInfoShops( $(this.content) );
					});
				}
				else{*/
					initInfoShops( $(this.content) );
				//}
			},
			close: function(){
				// destroyScroll();
			}
		}
	});
	$('.j-delivery-city').magnificPopup({
		items: {
			src: '#select-delivery-city-popup',
			type: 'inline'
		}
	});
	$('.js-popup-product-shop').magnificPopup({
		fixedBgPos: true,
		fixedContentPos: true,
		showCloseBtn: true,
		closeBtnInside: true,
		removalDelay: 0,
		preloader: true,
		type:"inline",
		mainClass: 'mfp-fade mfp-s-loading',
		galery: {enabled: true},
		items: {
			src: '#popup-availability',
			type: 'inline'
		},
		callbacks: {
			beforeOpen: function() {
				var selectedAttr = $('.js-popup-product-shop').data('attribute') || '';
				$('#popup-availability .j-info-shop-product-size').val(selectedAttr).trigger('change');
			},
			open: function(){
				// initScroll();
				if($('[data-id=ymaps]').length>0){
					loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
						$('[data-id=ymaps]').remove();
						initInfoShops( $(this.content) );
					});
				}
				else{
					initInfoShops( $(this.content) );
				}
				ajax_fetch_coupons( 'shops' );
			},
			close: function(){
				// destroyScroll();
			}
		}
	});
	$('.js-popup-product-images').magnificPopup({
		fixedBgPos: true,
		fixedContentPos: true,
		showCloseBtn: true,
		closeBtnInside: true,
		removalDelay: 0,
		preloader: true,
		type:"inline",
		mainClass: 'mfp-fade mfp-s-loading',
		galery: {enabled: true},
		items: {
			src: '#productPopupZoom',
			type: 'inline'
		},
		callbacks: {
			beforeOpen: function() {
				$('#productPopupZoom').closest('.mfp-wrap').css('opacity','0');
			},
			open: function(a,b,c,d){
				var _this = this;
				var button = $(this.st.el);
				var id = $(button).data('name');
				var wraper = $(this.content).closest('.mfp-wrap');
				wraper.imagesLoaded(function(){
					wraper.animate({ scrollTop: $('#'+id, wraper).offset().top - wraper.offset().top }, 0).css('opacity',1);
				});
			},
			close: function(){
			}
		}
	});

	if( $('#checkout_get_mail').length ) {
		$.magnificPopup.open({
			items: {
				src: '#checkout_get_mail',
				type: 'inline',
			},
			callbacks: {
				open: function(){
					setTimeout( function(){$('#checkout_get_mail input[name=email]').focus()}, 1000 );
				}
			}
		});
	}

	if( $('#message_from_support-popup').length ) {
		$.magnificPopup.open({
			items: {
				src: '#message_from_support-popup',
				type: 'inline',
			},
		});
	}

	$('.j-show-modal-onload').each(function() {
		$.magnificPopup.open({
			items: {
				src: this,
				type: 'inline',
			},
			callbacks: {
				open: function(){
					initPopupProductSlider( $.magnificPopup.instance.content );
				}
			}
		});
	});
	$(document).on('click', '.j-close-modal', function(e) {
		e.preventDefault();
		$.magnificPopup.close();
		$(this).closest('.modal').modal('hide');
	});
}

function initPopupProductSlider( wrapper ) {
	if( $('.js-products-slider', wrapper).length ) {
		if( !$('.js-products-slider', wrapper).hasClass('slick-initialized') ) {
			$('.js-products-slider', wrapper).css('opacity', 0);
			$('.js-products-slider', wrapper).slick({
				infinite: true,
				slidesToShow: 5,
				slidesToScroll: 1,
				dots: false,
				responsive: [
					{
						breakpoint: 940,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
						}
					}
				]
			});
		}
		$('.js-products-slider', wrapper).css('opacity', 1);
		$('.js-products-slider', wrapper).css('max-height', '');
		$('.js-products-slider', wrapper).slick('refresh');
	}
}

function initProductRating(){
	if($('.js-rating').length){
		$('.js-rating div.rating-star').on('click', function(){
			$(this).addClass('checked');
			$(this).find('input').attr('checked', 'checked');
			for(var i = 0; i < $('.js-rating div.rating-star').length; i++){
				if(i <= $(this).index()){
					$('.js-rating div.rating-star').eq(i).addClass('checked');
					$('.js-rating div.rating-star').eq(i).find('input').prop('checked', true);
				} else {
					$('.js-rating div.rating-star').eq(i).removeClass('checked');
					$('.js-rating div.rating-star').eq(i).find('input').prop('checked', false);
				}
			}
		});
	}
}

function initReviews(){
	if($('.reviews-item__buttons .comment-btn').length && $('.reviews-item .comment-form').length){
		$('.reviews-item__buttons .comment-btn').click(function(){
			$(this).parents('.reviews-item').find('.comment-form').slideToggle();
		});
	}

	if($('.reviews').length){
		$('.reviews .trigger').click(function(){
			$(this).hide();
			$(this).next('form').slideDown();
		});
	}

	if($('.js-reviews-item__text').length){
		$('.expand-text-btn').click(function(){
			$(this).parents('.reviews-item').find('.js-reviews-item__text').toggleClass('active');
			if ($.trim($(this).html()) === '������ ���������') {
	            $(this).html('������');
	            $(this).addClass('open');
	        } else {
	            $(this).html('������ ���������');
	            $(this).removeClass('open');
	        }
		});
	}
}

function initMasks(){
	if($('.js-tel-mask').length){
		$('.js-tel-mask').mask('+7 000 000 00 00');
	}
}

function initZoom(){
	if($('.js-zoom').length){
		$('.js-zoom').zoom({
			magnify: 1,
		});
	}
}

function initTabs(){
	$('.tabs-content .tab-item').eq(0).addClass('opened');

	$(".tabs .tab").click(function(){
		$('.tabs-content .tab-item').removeClass('opened');
		$(".tabs .tab").removeClass("active").eq($(this).index()).addClass("active");		
		$(this).parents('.tabs-container').find(".tabs-content .tab-item").hide().removeClass('active').eq($(this).index()).fadeIn().addClass('active');	
	}).eq(0).addClass("active");

	if($('.tabs').hasClass('numbered')){
		var numbers = $('.tabs').find('.num');

		for(var i=0; i < numbers.length; i++){
			numbers.eq(i).html(	(i+1) + ". " );
		}
	}

	if($('.js-btn-tabs').length){
		var tabText = $('.js-btn-tabs').next('.tabs').find('.tab.active').text();
		$('.js-btn-tabs').text(tabText);

		$('.js-btn-tabs').click(function(){
			$(this).toggleClass('active').next('.tabs').slideToggle();
		});

		$(".tabs .tab").click(function(){
			tabText = $(this).text();
			$(this).parents('.tabs-container').find('.js-btn-tabs').text(tabText);
		});
	}
}

function initRange(){
	var rangeEl = $( ".js-range" );
	if(rangeEl.length){
		rangeEl.slider({
			range: true,
			min: rangeEl.data('min'),
			max: rangeEl.data('max'),
			values: [ rangeEl.data('valueMin'), rangeEl.data('valueMax') ],
			slide: function( event, ui ) {
				$( ".filter-amount" ).html( "<div>" + ui.values[ 0 ] + " &#x20BD;</div><div>" + ui.values[ 1 ] + " &#x20BD;</span>" );
				$('.slider-range .from').val( ui.values[ 0 ] );
				$('.slider-range .to').val( ui.values[ 1 ] );
			},
			change: function( event, ui ) {
				if( event.bubbles ) {
					window.listing.info.prices = [0,0];
				}
				if( $(this).data('test') == undefined ) {
					$(this).data('test', 1);
					var otherRange = $('.js-range').not(this);
					otherRange.slider('values', ui.values);
				}
				if( event.bubbles ) {
					$('#filtr-form').trigger('change');
				}
			}
		});
		$( ".filter-amount" ).html( "<div>" + rangeEl.slider( "values", 0 ) + " &#x20BD;</div>" +
		"<div>" + rangeEl.slider( "values", 1 ) + " &#x20BD;</div>" );
	}
}

function initScroll(){
	if($('.popupWindow .js-scroll-popup').length){
		$('.js-scroll-popup').mCustomScrollbar({
			axis:"y",
			theme:"my-theme",
			autoExpandScrollbar:true,
			advanced:{autoExpandHorizontalScroll:true}
		});
	}

	if($('.js-scroll').length){
		$('.js-scroll').mCustomScrollbar({
			axis:"y",
			theme:"my-theme",
			autoExpandScrollbar:true,
			advanced:{autoExpandHorizontalScroll:true}
		});
	}
}

function destroyScroll(){
	if($('.popupWindow .js-scroll-popup').length){
		$('.js-scroll-popup').mCustomScrollbar("destroy");
	}
}

function intinQuickViewSlider(){
	if($('.js-product-photos__slider').length) {
		$('.js-product-photos__slider').slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 2,
		})/*.resize()*/;
		$('.js-product-photos__slider').on('click', 'img', function(){
			$('.js-product-photos__slider').slick('slickNext');
		});
	} else {
		console.log('Element was not found!')
	}
}

function initQuickViewScripts(){
	initMagnific();
	initExpandBlock();
	initStyler();
	intinQuickViewSlider();
}

function initSticky(bottomHeight){
	if($('.js-sticky').length){
		$('.js-sticky').sticky({
			topSpacing: 30,
			bottomSpacing: bottomHeight + 200,
		});
	}
}

function destroySticky(){
	if($('.js-sticky').length){
		$('.js-sticky').unstick();
	}
}

function setCatalogFilterCondition(catalogFilter){
	var buffer = new Array();
	var counter = 0;

	var checked = $('.list-checkbox__label input:not(.j-subgroup-checkbox):checked', catalogFilter);
	counter = checked.length;

	for(var i=0; i < counter; i++){
		buffer[i] = '<li>' + checked.eq(i).closest('label').find('.label-text').text() + '</li>';
		// if ( checked.eq(i).data('for') == 'fbrand' ) {
		// } else {
		// 	buffer[i] = '<li>' + checked.eq(i).val() + '</li>';
		// }
	}

	$('.condition-item', catalogFilter).html(buffer);
	$('.catalog-filter__condition .number, .dropDown-button .number', catalogFilter).html(counter);
	$('.dropDown-button .number', catalogFilter)[ counter > 0 ? 'show' : 'hide' ]();
	$(catalogFilter)[ counter > 0 ? 'addClass' : 'removeClass' ]('active');
	$('.j-reset-filters-head').removeClass('hidden');
}

function removeCatalogFilterCondition(catalogFilter){

	var labelChecked = $('label.checked', catalogFilter);
	var containerChecked = $('div.checked', labelChecked);
	var checkbox = $("input:checkbox", containerChecked);

	labelChecked.removeClass('checked');
	containerChecked.removeClass('checked');
	checkbox.removeAttr('checked', 'checked');

	$('.condition-item', catalogFilter).html(
		'<li>������ �� �������</li>'
		);
	$('.catalog-filter__condition .number, .dropDown-button .number', catalogFilter).html('0');
	$('.dropDown-button .number', catalogFilter).hide();
	$(catalogFilter).addClass('active');
	$('.j-reset-filters-head').addClass('hidden');
}
function getYaMap(mapID){
	var myMap = new ymaps.Map(mapID, {
			center: [55.7, 37.5],
			zoom: 9,
			controls: ['zoomControl']
		}),

		MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
			'<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
		),

		// ������� ���������.
		myCollection = new ymaps.GeoObjectCollection(),
		// ������� ������ � �������.
		myPoints = [
			{ coords: [55.77, 37.46], address: "�. ������, 24 �� ���� ������� �������", situation: "�� Vegas ��������� �." },
			{ coords: [55.66, 37.48], address: "�. ������, �. �������������, ��. �������, ��� 43, ������ 1", situation: "�� ���" },
			{ coords: [55.65, 37.42], address: "�. ������, �. ����, �������� ����, ��� � 211", situation: "�� ������� ������� ���������, �������� ��������" },
			{ coords: [55.64, 37.54], address: "�. ������, 24 �� ���� ������� �������", situation: "�� Vegas ��������� �." },
		];

	// ��������� ��������� �������.
	for (var i = 0, l = myPoints.length; i < l; i++) {
		var point = myPoints[i];
		myCollection.add(new ymaps.Placemark(point.coords, {
			hintContent: point.situation,
			balloonContent: point.address,
			//iconContent: '12'
		}, {
			// �����.
			// ���������� ������� ������ ��� ������.
			iconLayout: 'default#imageWithContent',
			// ��� ����������� ������ �����.
			iconImageHref: '/templates/iridato/img/icons/icon-mapMarker.png',
			// ������� �����.
			iconImageSize: [51, 51],
			// �������� ������ �������� ���� ������ ������������
			// � "�����" (����� ��������).
			iconImageOffset: [-25, -25],
			// �������� ���� � ���������� ������������ ���� � ���������.
			iconContentOffset: [0, 0],
			// ����� �����������.
			iconContentLayout: MyIconContentLayout
		}));
	}

	// ��������� ��������� ����� �� �����.
	myMap.geoObjects.add(myCollection);
}
function initMap(mapID){
	if($('.js-map').length){
		try {
			ymaps.ready(function(){getYaMap(mapID);});
		}
		catch(error){
			console.log('Error, Yandex Map is not available');
		}
	}
}

function initInfoShops(wrapper) {
	/*
	ymaps.ready(function() {
		$('.j-info-shop-map', wrapper).each( function() {
			var shopsWraper = $(this);
			if( shopsWraper.data('initialized') == 1 ) {
				return;
			}
			shopsWraper.data('initialized', 1);
			var city = $('select[name=shop_city]', shopsWraper).val();
			var mapElement = $('.j-info-shops-map', shopsWraper);
			var map = new ymaps.Map(mapElement[0],
				ymaps.util.bounds.getCenterAndZoom(
					$('.j-info-shop-map-wraper', shopsWraper).data('start'),
					[mapElement.width(), mapElement.height()]
				));
			map.behaviors.enable(["default"]);
			map.behaviors.disable(["scrollZoom", "rightMouseButtonMagnifier", "dblClickZoom", "MultiTouch"]);
			map.controls.add("zoomControl");
			map.controls.add("typeSelector");
			map.controls.remove("fullscreenControl");
			var infoShopsOptions = {
				city: city,
				uri: shopsWraper.data('optionsUri'),
				hasList: shopsWraper.data('optionsList'),
				openBalloon: shopsWraper.data('openBalloon'),
				wraper: shopsWraper,
				afterUpdateListCallback: function() {
					setInfoShopsListHandlers( this );
					$('.js-scroll-popup', shopsWraper).mCustomScrollbar({
						axis:"y",
						theme:"my-theme",
						autoExpandScrollbar:true,
						advanced:{autoExpandHorizontalScroll:true}
					});
					map.container.fitToViewport();
					$('.result-line .amount', shopsWraper).html( this.points[ this.city ].qty_text );
				},
				beforeUpdateListCallback: function() {
					$('.chosenShop', shopsWraper).html('');
					setInfoShopsListHandlers( this );
					$('.js-scroll-popup', shopsWraper).mCustomScrollbar('destroy');
				},
				afterOpenListItemCallback: function(id) {
					var pointInfo = this.points[ this.city ].list[ id ]
					$('.chosenShop', shopsWraper).html( $.render[ 'shops_chosen' ].render( [ pointInfo ] ) );
					map.container.fitToViewport();
				}
			};
			if( shopsWraper.data('url') ) {
				infoShopsOptions.url = shopsWraper.data('url');
			}
			if( shopsWraper.data('ajaxActionParam') ) {
				infoShopsOptions.ajaxActionParam = shopsWraper.data('ajaxActionParam');
			}
			var infoShops = new InfoShops( infoShopsOptions );
			infoShops.setMap( map );
			$(shopsWraper).on('click', '.j-info-shop-list-item', function(e) {
				e.preventDefault();
				if( !$(this).hasClass('active') ) {
					infoShops.openListItem( $(this).data('id') );
					infoShops.setPointUri( $(this).data('id') );
					infoShops.openPlacemark( $(this).data('id') );
					infoShops.setStaticMap( $(this).data('id') );
				}
			});

			$(shopsWraper).on('change', 'select[name=shop_city]', function() {
				var loader = $(this).closest('.box-field');
				loader.addClass('loading');
				infoShops.setCity( $(this).val() ).done(function() {
					infoShops.setCityUri();
					loader.removeClass('loading');
				});
			});

			$(shopsWraper).on('change', 'select[name=metro]', function() {
				if( $(this).val().trim().length ) {
					infoShops.sortList('distance', {'fromMetro': $(this).val()});
				} else {
					infoShops.sortList('id');
				}
				$('.j-info-shop-list').scrollTop(0);
			});

			var optiosnSelect = $('select.j-info-shop-product-size', shopsWraper);
			if( optiosnSelect.length ) {
				optiosnSelect.on('change', function() {
					infoShops.setAjaxParam($(this).attr('name'), $(this).val());
					infoShops.refreshCities();
				});
				if( optiosnSelect.val().length ) {
					infoShops.setAjaxParam(optiosnSelect.attr('name'), optiosnSelect.val());
				}
			}

			infoShops.getListByCity( city ).done(function(points){
				infoShops.putPointsForCity( city, true );
				// infoShops.setStaticMap();
				// infoShops.updateList( points.list );
			});
			setInfoShopsListHandlers( infoShops );
			function setInfoShopsListHandlers( infoShops ) {
			}
			if( $('.j-info-shop-list-item.active',shopsWraper).length ){
				var shop_id = $('.j-info-shop-list-item.active', shopsWraper);
				shop_id = shop_id.data('id');
				var city = $('select[name=shop_city]', shopsWraper).val();
				infoShops.getListByCity( city, true ).done(function( points ){
					infoShops.updateList( points.list, shop_id );
					infoShops.putPointsForCity( city );
					infoShops.updateMetro( points.city.metro );
					infoShops.setStaticMap();
				}).done( function(){
					infoShops.openListItem( shop_id );
					infoShops.setStaticMap( shop_id );
					setTimeout(function(){
						// infoShops.setPointUri( shop_id );
						infoShops.openPlacemark( shop_id );
					}, 500);
				});
			}
		} );
	});
	*/
}

function initListing() {
	initListingSort();

	/**
	 * ������� ���� ������ ���������� ����� ������
	 */
	function initListingSort(){
		$('.js-choose-sort').click(function(){
			var parent = $(this).closest('.js-dropDown');

			$('.js-close-drop-down', parent).trigger('click');
		});
	}

	if( $('#filtr-form').length == 0 ) {
		return '';
	}
	window.select_form_start_position = $('#filtr-form').offset().top - 50;
	window.listing = new Listing();
	window.listing.parseOptions();

	var filterForm = $('#filtr-form');
	$('input:checkbox', filterForm).on('change', function(e) {
		var checkbox = $(e.target);
		if ( !checkbox.prop('checked') && checkbox.closest('.subgroup-list').length ) {
			checkbox.closest('.subgroup-list').find('.j-subgroup-checkbox').prop('checked', false).removeClass('checked');
		}
		listing.setMultiselectHiddenValue( this );
	});
	// SINC FILTRS ON 2 FORMS - START
	$('input:checkbox', filterForm.add( $('.fake-cat-form') )).on('change', function(e, a) {
		if( a !== 'noChange' ) {
			var otherInput = $('input:checkbox[data-for="' + $(this).data('for') + '"][data-group="' + $(this).data('group') + '"][value="' + $(this).val() + '"]');
			otherInput.not(this).prop('checked', $(this).prop('checked')).trigger('refresh');
			otherInput.trigger('change-view');

			var inputOnGoodForm = $('input:checkbox[data-for="' + $(this).data('for') + '"][data-group="' + $(this).data('group') + '"][value="' + $(this).val() + '"]', filterForm);
			if( !inputOnGoodForm.is( $(this) ) ) {
				otherInput.not(this).trigger('change', 'noChange');
			}
		}
		if ( $(e.target).hasClass('j-subgroup-checkbox') ) {
			var group = $(e.target).closest('.subgroup-list');
			group.find('.jq-checkbox:not(.j-subgroup-checkbox) input').each(function(){
				$(this).prop('checked', $(e.target).prop('checked')).trigger('refresh');
				var otherSubInput = $('input:checkbox[data-for="' + $(this).data('for') + '"][data-group="' + $(this).data('group') + '"][value="' + $(this).val() + '"]');
				otherSubInput.not(this).prop('checked', $(this).prop('checked')).trigger('refresh');
				otherSubInput.trigger('change-view');
				var SubInputOnGoodForm = $('input:checkbox[data-for="' + $(this).data('for') + '"][data-group="' + $(this).data('group') + '"][value="' + $(this).val() + '"]', filterForm);
				if( !SubInputOnGoodForm.is( $(this) ) ) {
					otherSubInput.not(this).trigger('change', 'noChange');
				}
			});
		}
	});
	$('input:checkbox', filterForm.add( $('.fake-cat-form') )).on('change-view refresh', function(e) {
		var wraper = $(this).closest('.multifilter-item-new');
		wraper[ $(this).prop('checked') ? 'addClass' : 'removeClass' ]('active');
		$(this).closest('.list-checkbox__label')[ $(this).prop('checked') ? 'addClass' : 'removeClass' ]('checked');
		setCatalogFilterCondition($(this).closest('.catalog-filter__item'));
	});
	// SINC FILTRS ON 2 FORMS - END

	filterForm.on('change', function(e) {
		if ( $(e.target).hasClass('j-subgroup-checkbox') ) {
			var group = $(e.target).closest('.subgroup-list');
			group.find('.jq-checkbox:not(.j-subgroup-checkbox) input').each(function(){
				$(this).prop('checked', $(e.target).prop('checked')).trigger('refresh');
				listing.setMultiselectHiddenValue( this );
				setCatalogFilterCondition($(this).closest('.catalog-filter__item'));
			});
			$('input[name=page]', filterForm).val( 1 );
			$(this).submit();
			if( window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr != undefined ) {
				eval( window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr.code );
			}
			return;
		}
		if( !$(e.target).hasClass('j-select-size-type') ) {
			$('input[name=page]', filterForm).val( 1 );
			$(this).submit();
			if( window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr != undefined ) {
				eval( window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr.code );
			}
		}
	});

	$('.loadmore a').on('click', function(e) {
		e.preventDefault();
		$('#show_more').val( 1 );
		$('input[name=page]', filterForm).val( parseInt($('input[name=page]', filterForm).val()) + 1 );
		$(this).addClass('loading');
		filterForm.submit();
	});
	$('.link-prev-one').on('click', function(e) {
		e.preventDefault();
		$('#show_more').val( 2 );
		window.listing.lastPage = $('input[name=page]', filterForm).val();
		$('input[name=page]', filterForm).val( parseInt( $(this).data('page') ) );
		$(this).data('page',  $(this).data('page') - 1 );
		$(this).addClass('loading');
		filterForm.submit();
	});
	$('.catalog-filter__item .js-clear-button').click(function(){
		var thisCatalogFilter = $(this).parents('.catalog-filter__item');

		removeCatalogFilterCondition(thisCatalogFilter);
		listing.clearFiltr( this );
	});
	$('.j-reset-filters', filterForm.add( $('.fake-cat-form') )).click( function(e) { // �������� ��� �������
		e.preventDefault();
		listing.clearForm();
		$('.catalog-filter__item').removeClass('active');
		$('.catalog-filter__condition .number, .dropDown-button .number').html('0');
		$('.dropDown-button .number').hide();
		$('.j-reset-filters-head').addClass('hidden');
	} );

	listing.hideFiltrs();
	$('.hidden-filtr-toggler').click(function( e ) {
		e.preventDefault();
		listing.toggleHiddenFiltrs();
	});
	$(document).on('mouseleave', '.catalog-filter__item', function(){
		$(this).find('.dropDown-trigger.active').trigger('click');
	});

	$(document).on('click', '.j-reset-filters-head', function(){
		$('.j-reset-filters', filterForm).trigger('click');
	});
}

$('#see-map').magnificPopup({
	items: {
		src: '#map-popup',
		type: 'inline'
	},
});

$(document).on('click', '.btn-see-map', function( e, delivery ) {
	e.preventDefault();
	var form = $(this).closest('form');
	$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
	var tmp_options = form.serializeArray();
	var options = {};
	for (var i = 0; i < tmp_options.length; i++) {
		options[ tmp_options[i].name ] = tmp_options[i].value;
	}
	$('#see-map').trigger('click');
	if($('[data-id=ymaps]').length>0){
		loadScript($('[data-id=ymaps]').attr('data-src')).then(function () {
			$('[data-id=ymaps]').remove();
			ymaps.ready(function() {
				$('#map-popup').shopsMap( options );
			});
		});
	}
	else{
		ymaps.ready(function() {
			$('#map-popup').shopsMap( options );
		});
	}
});

$(document).on('click', '#map-popup .map-tab li', function(){
	var index = $(this).index();
	$(this).addClass('active').siblings().removeClass('active');
	$(this).closest('.map-tab').find('.tab').eq( index ).addClass('active').siblings().removeClass('active');
});
$(document).on('click', '.more_bottom_info_btn', function(e){
	e.preventDefault();
	$(this).hide();
	$('.more_bottom_info').slideDown();
});

$(document).on('click', '.more-description', function(e){
	var text = $(this).next('.full-info').html();
	$.magnificPopup.open({
		items: {
			src: '#return-info-popup',
			type: 'inline',
		},
		callbacks: {
			beforeOpen: function(){
				$('#return-info-popup .popupWindow-container').html( text );
			}
		}
	});
});

$(document).on('click', '.j-wish-delete-item', function(e) {
	e.preventDefault();
	var products_id = $(this).data('products_id');
	wishlist.remove( products_id, function(){
		if( PROSKTR.content == 'wishlist' ) {
			$('.j-wish-check[value=' + products_id + ']').closest('.cart-products__item ').slideUp();
		}
	});
});

$(document).on('click', 'a[href="#popup-sizes"]', function(){
	var opener = this;
	$.magnificPopup.open({
		items: {
			src: '#sizes-description',
			type: 'inline',
		},
		callbacks: {
			beforeOpen: function(){
				$('.default-ajax-form').ajaxForm( default_ajax_form_options );
			},
			open: function() {
				$(opener).closest('.product').find('.sized-description-form').submit();
			}
		}
	});
});

$(document).on('click', '.quick-order', function(e) {
	e.preventDefault();
	var products_id = $(this).data('products-id');
	var order_type = $(this).hasClass('booking') ? 'booking' : 'fast_order';
	var info_shop_id = $(this).data('info_shop_id');
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {
			src: '#popup-oneClick',
			type: 'inline',
		},
		callbacks: {
			beforeOpen: function() {
				$.ajax({
					url: '/order_one_click.php?action=getForm',
					data: {products_id: products_id, order_type: order_type, info_shop_id: info_shop_id},
					type: 'post',
					dataType: 'json',
					success: function( response ){
						$('#popup-oneClick').html( response.orderOneClick );
						$('#popup-oneClick .js-styled').styler();
						$('.tabs-body', this.container).tabs();
						$("#fast-order-form", this.container).validate();
						$('.phone-11-dig', this.container).phoneMask();
						$('.chooseSize', this.container).on('change', 'input[name^=prod_id]', function(){
							$('input[name=option_id]').val('1');
							$('input[name=option_value]').val( $(this).val() );
						});
						$("#fast-order-form", this.container).submit(function(e) {
							if ( $('input[name=option_value]', this).val() == '' && ( $('input[name=firstname]', this).val() != '' || $('input[name=firstname]', this).length == 0 ) && ( $('input[name=phone]', this).val() != '' || $('input[name=phone]', this).length == 0 ) ) {
								e.preventDefault();
								$('#popup-oneClick .drop-size-bg').show();
								$('#popup-oneClick .drop-size').show();
								return false;
							}
							if( !e.isDefaultPrevented() ) {
								$('input:submit', this).hide();
								$('.order-success-btn', this).removeClass('mfp-hide');
							}
						});
						$('#popup-oneClick', this.container).on('change', 'input[name^=prod_id]', function(){
							if( $(this).closest('.drop-size', this).length ) {
								var wrapper = $('#popup-oneClick .quickorder_product_sizes');
								var otherInput = $('input[name="prod_id[1]"][value="' + $(this).val() + '"]', wrapper).prop('checked', true).trigger('change');
							} else {
								$('input[name=option_id]').val('1');
								$('input[name=option_value]').val( $(this).val() );
								if ( $('#popup-oneClick .drop-size').css('display') == 'block' ) {
									$('#popup-oneClick .drop-size').hide();
									$('#popup-oneClick .drop-size-bg').hide();
									$("#fast-order-form").submit();
								}
							}
						});
						$('#product-tab2', this.container).on('click', '.pass-btn', function(e) {
							e.preventDefault();
							var el = $(this);
							var form = el.closest('form');
							var customerInfo = {};
							customerInfo.pass = $('input[name=password]', form).val();
							customerInfo.email = $('input[name=email_or_phone]', form).val();
							customerInfo.options = {email_or_phone: true};
							customer.login({
								customerInfo: customerInfo,
								error: function( msg ) {
									form.find('.hint').addClass('error').html(msg.err_mes);
								},
								success: function( msg ) {
									$('.navigation-tabs a[href=#product-tab1]').trigger('click');
									var orderForm = $('#fast-order-form');
									$('input[name=firstname]', orderForm).val( msg.name + " " + msg.lastname );
									$('input[name=phone]', orderForm).val( msg.phone );
									if( msg.asLastOrder=='1' ) {
										$('input[name=same_order]', orderForm).val( 1 );
									}
									orderForm.submit();
								}
							});
						});
						$('#popup-oneClick', this.container).on('click', '.pass-forgotten', function(e) {
							e.preventDefault();
							el = this;
							form = $(el).closest('form');
							$(el).addClass('loading');
							customer.sendNewPass({
									email_address: form.find('input[name=email_address],input[name=email_or_phone]').val()
								},
								function( msg ) {
									$(el).closest('form').find('.hint').html(msg);
									$(el).removeClass('loading');
								}
							);
						});
						$(document).on('click', '#popup-oneClick .toLoginTab', function(e){
							e.preventDefault();
							var telephone = $(this).closest('.j-value').find('input[name=phone]').val();
							$('#product-tab2 input[name=email_or_phone]').val( telephone ).trigger('change');
							$('.navigation-tabs a[href=#product-tab2]').trigger('click');
						});
						ajax_fetch_coupons( 'order' );
					}
				});
			}
		}
	});
});
$(document).on('click', '.headerInfoShopCity', function(e){
	e.preventDefault();
	var city = $(this).text();
	$.ajax({
		url: '/ajax.php?action=getDeliveryBeneft',
		type: 'post',
		data: { city: city, ajax: 3 },
		success: function(){
			$('.j-info-shop-city').html( city ).trigger('click');
			$('.j-info-shop-city-input').val( city );
			if ( $('.j-reload-city').length ) {
				$('.j-reload-city').submit();
			}
		}
	});
});
$(document).on('click', '.drop-size-bg', function( e ) {
	$(this).hide();
	$('.drop-size').hide();
});
$(document).on('change', '.product .addToBag-container .drop-size select.j-change-add-trigger, .product .addToBag-container .drop-size input.j-change-add-trigger', function() {
	var input = $(this);
	var form = $('form.j-add-product-form');
	$('.drop-size').hide();
	$('.drop-size-bg').hide();
	var addBtn = $('.j-add-product');
	$('.j-change-add-trigger', form).val( input.val() ).trigger('refresh');
	addBtn.trigger('click');
});
$(document).on('click', '#productPopupZoom', function(){
	$.magnificPopup.close();
});

$('#infoPagesPopup .tabs-body').each(function(){
	var active = $(this).find('.navigation-tabs .active');
	active = active.length ? active.index() : 0;
	$(this).tabs({
		active: active
	});
});
$(document).on('click', '.chooseSize__label', function(){
	var list = $(this).closest('.chooseSize-list');
	var textHolder = $(this).closest('.product').find('.product-availability span');
	if ( $(this).hasClass('disabled') ) {
		$('.chooseSize__label.disabled', list).removeClass('active');
		$(this).addClass('active');
		$('input:radio:checked', list).prop('checked', false).trigger('refresh');
		var input = $('input:radio', this);
		$(textHolder).text('��� � �������');
		$(this).closest('.product').find('.j-add-product').addClass('mfp-hide').next('.j-add-product-done').addClass('mfp-hide').next('.j-same-products-link').removeClass('mfp-hide');
		var same_products_link_el = $(this).closest('.product').find('.j-add-product').nextAll('.j-same-products-link');
		var same_products_link_text = $('.label-text', this).text();
		same_products_link_el.attr('href', input.data('same_products_link')).text( '��� ������ ������� ' + same_products_link_text );
		$('.js-popup-product-shop').data('attribute', $('input', this).val()).addClass('red-font');
	} else {
		$('.chooseSize__label', list).removeClass('active');
		$(textHolder).text('� �������');
		$(this).closest('.product').find('.j-add-product').removeClass('mfp-hide').next('.j-add-product-done').addClass('mfp-hide').next('.j-same-products-link').addClass('mfp-hide');
		$('.js-popup-product-shop').removeClass('red-font');
	}
});
$(document).on('submit', '#account_history_info-cancel form', function(e){
	e.preventDefault();
	var popup = $(this).closest('#account_history_info-cancel');
	popup.find('.js-cancel').hide();
	popup.find('.js-success').show();
});
$(document).on('change', '.catalog-head__right input[name=fsort], .fake-cat-form input[name=fsort]', function(){
	$('#filtr-form input[name=fsort]').val( $(this).val() ).trigger('change');
});

// $(function(){
// 	var active = $('.header-navMobile .tabs-body').find('.navigation-tabs .active');
// 	active = active.length ? active.index() : 0;
// 	$('.tabs-body', '.header-navMobile').tabs({
// 		active: active
// 	});
// });

function checkFixedAddButtomProduct() {
	if( window.PROSKTR.content == "product_info" ) {
		var colorEl = $('section.product .product-info__sale').length ? $('section.product .product-info__sale') : $('section.product .product-info__price');
		var btnEl = $('section.product .functional-buttons');
		var staticPositionTop = colorEl.offset().top;
		var staticPositionBtn = btnEl.offset().top;
		var staticHeight = colorEl.height();
		var toFixElement = $('section.product .functional-buttons .to-fix');
		var formHeight = $('#product-add-btn', toFixElement).height();
		var currentScroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		var fixedTopWrap = $('.fixed-top');
		var fixedBottomWrap = $('.fixed-bottom');
		var btnWrapWidth = $('section.product .product-info__head').width();
		var windowWidth = window.innerWidth || $(window).width();
		if ( windowWidth > 991 ) {
			fixedBottomWrap.hide();
			if ( currentScroll > staticPositionTop ) {
				fixedTopWrap.show();
			} else {
				fixedTopWrap.hide();
			}
			if ( currentScroll > staticPositionBtn - 51 ) {
				toFixElement.css( 'width', btnWrapWidth );
				// btnEl.css({ height: toFixElement.height(), 'margin-bottom': '10px'});
				toFixElement.addClass('fixed').removeClass('fixed-btn-bottom');
				var topVal = staticPositionBtn - currentScroll;
				topVal = topVal > 51 ? 51 : topVal;
				topVal = topVal < 0 ? 0 : topVal;
				toFixElement.css('top', topVal + 'px');
			} else {
				toFixElement.css('top', '');
				$(toFixElement).css( 'width', '' );
				// btnEl.css({ height: '', 'margin-bottom': '0'});
				toFixElement.removeClass('fixed').removeClass('fixed-btn-bottom');
			}
		} else {
			toFixElement.css('top', '');
			if ( currentScroll > staticPositionBtn ) {
				$(toFixElement).css( 'width', btnWrapWidth );
				fixedBottomWrap.hide();
				fixedTopWrap.show();
				toFixElement.addClass('fixed').removeClass('fixed-btn-bottom');
				if ( windowWidth <= 479 ) {
					btnEl.css('padding-top', '56px');
				}
			} else if ( currentScroll + windowHeight < staticPositionBtn ) {
				$(toFixElement).css( 'width', btnWrapWidth );
				fixedBottomWrap.show();
				fixedTopWrap.hide();
				toFixElement.addClass('fixed').addClass('fixed-btn-bottom');
				btnEl.css('padding-top', '');
			} else {
				$(toFixElement).css( 'width', '' );
				fixedBottomWrap.hide();
				fixedTopWrap.hide();
				toFixElement.removeClass('fixed').removeClass('fixed-btn-bottom');
				btnEl.css('padding-top', '');
			}
		}
	}
}
$(document).on('click', '.cart-change-size', function(){
	var editSizeProductLayerEl = $('#change-item-modal .popupWindow-container');
	var tdInfo = $(this).closest('.cart-products__item');
	var image = $(this).data('image');
	var currentQty = $(this).data('qty');
	var sendData = {
		'id': {},
		'products_id': {},
		'clicked': '��������',
		'action': 'get_info'
	};
	sendData['id'][ tdInfo.data('id') ] = {};
	sendData['id'][ tdInfo.data('id') ][ 1 ] = tdInfo.data('id');
	sendData['products_id'][0] = tdInfo.data('id');
	sendData['products_id'][0] = tdInfo.data('id');
	$.magnificPopup.open({
		items: {
			src: '#change-item-modal',
			type: 'inline',
		},
		callbacks: {
			beforeOpen: function(){
				$.ajax({
					url: '/ajax_shopping_cart.php',
					type: 'POST',
					dataType: 'json',
					data: sendData,
					success: function( msg ) {
						editSizeProductLayerEl.html( $.render[ 'edit-size-product-layer' ].render( msg ) );
						$('.size-img').attr('src', image);
						$('form[name=cart_quantity]').on('change', 'select.change_products_size', function () {
							var select_qty = $(this).closest('form').find('select.change_products_qty');
							select_qty.styler('destroy');
							select_qty.empty();
							var max_qty = Math.min(parseInt($(this).find('option:selected').attr('qty')), 10);
							for (var i = 1; i <= max_qty ; i++) {
								select_qty.append("<option" + ( i == currentQty ? ' selected' : '' ) + ">"+i+"</option>");
							}
							select_qty.val( Math.min(max_qty, parseInt($(this).attr('qty'))) ).trigger('change');
							select_qty.styler();
						});
						$('form[name=cart_quantity]').on('change', 'select.change_products_qty', function() {
							$(this).closest('form').find('select.change_products_size').attr('qty', $(this).val());
						});
						$('form[name=cart_quantity] select.change_products_size').trigger('change');
						$('form[name=cart_quantity] select').each(function(){
							$(this).styler('destroy').styler();
						});
						$(editSizeProductLayerEl).on('submit', 'form', function(e){
							e.preventDefault();
							$.ajax({
								url: $(this).attr('action'),
								type: 'post',
								dataType: 'json',
								data: $(this).serializeArray(),
								success: function( answer ) {
									var productsHtml = $.render[ 'show_product_tpl' ].render( answer.products, { currencies: window.PROSKTR.currencies } );
									$('.cart-products').html('');
									$('.cart-products').append(productsHtml);
									$.magnificPopup.close();
									shoppingCart.setQuantityNPrice( answer );
								}
							});
						});
					}
				});
			},
			open: function() {
			}
		}
	});
});

if ( $('#map_address').length ) {
	ymaps.ready(function(){
		myMap = new ymaps.Map("map_address", {
			center: [55.923014, 37.514158],
			zoom: 16
		});
		myPlacemark = new ymaps.Placemark([55.923014, 37.514158], {
			hintContent: '5�����N��', 
			balloonContent: '����������. ���., �. ������������,<br>����������� ��-�, �. 4, ���. 2.<br>���.: +7 (495) 708-40-34'
		});
		myMap.geoObjects.add(myPlacemark);
	});
}

$('.contacts').on('click', '.showHowToFind', function(e){
	e.preventDefault();
	$('.contacts .howToFind').slideToggle();
});

if ( $('#popup_500_5day').length ) {
	$.magnificPopup.open({
		items: {
			src: '#popup_500_5day',
			type: 'inline',
		},
	});
}

$('#coupon__5day').click(function(e){
	e.preventDefault();

	var popup = $(this).closest('#popup_500_5day');
	if( $(this).hasClass('close-500') ){
		$.magnificPopup.close();

		var telephone = popup.find('input[name=telephone]').val();
		var firstname = popup.find('input[name=firstname]').val();
		var customers_id = popup.find('input[name=customers_id]').val();
		if( customers_id.length && (telephone.length || firstname.length) ) {
			$.ajax({
			url: '/sent_coupon_500_5day.php',
				data : {
					update : true,
					customers_id : customers_id,
					telephone : telephone,
					firstname : firstname,
					ajax : 1,
				},
			});
		}
		return true;
	}
	var input = popup.find('input[name=email]');
	var email = input.val();
	var btn = $(this);
	if( email.length ){
		$.ajax({
			url: '/sent_coupon_500_5day.php',
			data : {
				email : email,
				ajax : 1,
			},
			dataType: 'json',
			success: function(msg){
				if(msg['err_mes'] != undefined ){
					$('.err_mes').text( msg['err_mes'] );
					input.val('');
				} else {
					popup.find('.h2').html('�������!');
					popup.find('.siver_half span.js-text').html('��� ����� �� ���������� �������� ������������!').addClass('green');
					popup.find('.holder.more_info').show();
					// popup.find('.siver_half span:not(.placeholder)').css('padding','8px');
					popup.find('input[name=customers_id]').val(msg['customers_id']);
					input.closest('.holder').hide();
					btn.html('�������� � ������� � ��������!');
					btn.addClass('close-500');
					popup.find('input[name=firstname]').focus();
					customer.mailCreateSuccess( msg );
					return true;
				}
			}
		});
		return false;
	} else {
		input.css('border','1px solid red');
		return false;
	}

});

$('#popup_500_5day').on('keydown', 'input' , function(e) {
	if(e.keyCode === 13) {
		$('#coupon__5day').trigger('click');
	}
});

$( window ).on('mousemove', checkShowExit);

$( document ).ready(function(){

	//����������� ������� �������� �������
	var copyopen = cookieObj.getCookie('copyopen');
	if( copyopen === undefined ) copyopen = 0;
	copyopen++;
	cookieObj.setCookie('copyopen', copyopen );

});

$( window ).on('unload', function(){
	//��������� ������� �������� �������

	var copyopen = cookieObj.getCookie('copyopen');
	if( copyopen === undefined ) copyopen = 0;
	copyopen--;
	cookieObj.setCookie('copyopen', copyopen );

});

function checkShowExit(e){
	var positionY = e.pageY - $(window).scrollTop();
	if(positionY <= 5){
		showExitPopup();

	}
}

function showExitPopup(){
	// return;
	var src = false;
	var type = 'reload_exitPopup_viewed';
	//�������� ���������� �������� �������
	// if( cookieObj.getCookie('jenechka') == 1 ){
	// 	$('#exit-popup-viewed-opener').trigger('click');
	// }
	if ( $(window).outerWidth() < 1024 ) return;
	if( cookieObj.getCookie('copyopen') != 1 ) return; // TODO UNCOMMENT
	if ( PROSKTR.content == 'shopping_cart' ) return;

	//��������� ��������� ��� ���
	if( cookieObj.getCookie('customerid') != 0 && cookieObj.getCookie('customerid') !== undefined) return; // TODO UNCOMMENT
	if( PROSKTR.customer.id != 0 ) return; // TODO UNCOMMENT
	//��������� �������

	if( cookieObj.getCookie('customerorderCnt') == 0 || cookieObj.getCookie('customerorderCnt') === undefined ){

		if( cookieObj.getCookie('exitpopup_viewed') == 1 ) return; // TODO UNCOMMENT
		if( cookieObj.getCookie('product_id_viewed_cur') === undefined ) return; // TODO UNCOMMENT
		if( cookieObj.getCookie('product_id_viewed_cur').length === 0  ) return; // TODO UNCOMMENT
		if( cookieObj.getCookie('product_id_viewed_cur').split(',').length < 2  ) return; // TODO UNCOMMENT

		src = '#exit-popup-viewed';
		if(window.ga) ga('send', 'event', 'Popup', 'Popup_ViewedProducts', 'ViewedProducts_Show', {'nonInteraction': 1});
		cookieObj.setCookie('exitpopup_viewed', 1);
	}else{
		if( cookieObj.getCookie('exitpopup_basket') == 1 ) return; // TODO UNCOMMENT
		if(window.ga) ga('send', 'event', 'Popup', 'Popup_ShoppingCartProducts', 'ShoppingCartProducts_Show', {'nonInteraction': 1});
		src = '#exit-popup';
		type = 'reload_exitPopupBottom';
		cookieObj.setCookie('exitpopup_basket', 1);
	}
	if ( src ) {
		$.magnificPopup.open({
			items: {
				src: src,
				type: 'inline',
			},
			callbacks: {
				open: function(){
					$.ajax({
						url: '/ajax.php?type=' + type,
						dataType: 'json',
						success: function (rtn) {
							$('.j-product-gallery').html( rtn['html'] );
							initPopupProductSlider( $.magnificPopup.instance.content );
						}
					});
					setTimeout( function(){$('input[name=email]', $.magnificPopup.instance.content).focus()}, 100 );
				}
			}
		});
	}
	$( window ).off('mousemove', checkShowExit);
}

$('#popup-authorization').on('click', '.postCard', function(){
	var form = $('.loyalty-card-form');
	var phone = $('input[name=telephone]', form ).val();
	var loyalty_card = $('input[name=loyalty_card]', form ).val();
	form.addClass('loading');
	$.ajax({
		url: '/ajax_customer.php?ajax=1&action=loyaltyCard',
		type: 'POST',
		dataType: 'json',
		data: {'telephone': phone, 'loyalty_card': loyalty_card, 'type': 'postCard'},
		success: function(msg){
			if ( msg['reload'] ) {
				location.reload();
			}
			if ( msg['result'] == 1 ) {
				customer.mailLoginSuccess( msg );
				$('.loginHide').addClass('hidden');
			} else {
				$('#popup-authorization .answer').html(msg['err_mes']);
				form.removeClass('loading');
			}
		}
	})
});

$('#popup-authorization').on('click', '.getCode', function(){
	var form = $('.loyalty-card-form');
	var phone = $('input[name=telephone]', form ).val();
	form.addClass('loading');
	$.ajax({
		url: '/ajax_customer.php?ajax=1&action=loyaltyCard',
		type: 'POST',
		dataType: 'json',
		data: {'telephone': phone, 'type': 'getCode'},
		success: function(msg){
			if ( msg['getCodeRes'] == 'ok' ) {
				$('.apply-code-form').removeClass('hidden');
				$('.default-card-form').addClass('hidden');
			} else {
				$('#popup-authorization .answer').html(msg['err_mes']);
			}
			form.removeClass('loading');
		}
	})
});

$('#popup-authorization').on('click', '.postCode', function(){
	var form = $('.loyalty-card-form');
	var phone = $('input[name=telephone]', form ).val();
	var apply_code = $('input[name=apply_code]', form ).val();
	form.addClass('loading');
	$.ajax({
		url: '/ajax_customer.php?ajax=1&action=loyaltyCard',
		type: 'POST',
		dataType: 'json',
		data: {'telephone': phone, 'apply_code': apply_code, 'type': 'postCode'},
		success: function(msg){
			if ( msg['reload'] ) {
				location.reload();
			}
			if ( msg['result'] == 1 ) {
				customer.mailLoginSuccess( msg );
				$('.loginHide').addClass('hidden');
			} else {
				$('#popup-authorization .answer').html(msg['err_mes']);
				form.removeClass('loading');
			}
		}
	})
});

$(document).on('click', '#show_more_about_returns', function(e){
	e.preventDefault();
	$('#more_about_returns').slideToggle();
});

$(document).on('click', '#show_more_about_returns2', function(e){
	e.preventDefault();
	$('#more_about_returns2').slideToggle();
});

$(document).on('click', '.has-drop a', function(e){
	e.preventDefault();
	$(this).closest('.has-drop').toggleClass('opened');
});

$(document).on('click', '#show-check-image', function(){
	$('#check-image').slideToggle();
});

$(document).on('click', '.j-action_popup', function(e){
	e.preventDefault();
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {
			src: '#popup-sale-action',
			type: 'inline',
		},
	});
});

$(document).on('change', '.j-bundles select', function(e) { // TODO ����������!!!
	var wrap = $(this).closest('.j-bundles');
	var bundles = wrap.data('bundles');
	if( $(this).data('key') == 0 ) { // ������� �����
		var select = $('select[name=option_1]', wrap);
		var disabledoptions = $('option', select).filter( function() {
			return $(this).val() !== '';
		} );
		if( $(this).val() == '' ) {
			disabledoptions.prop('disabled', false);
		} else {
			$.each( bundles[ $(this).val() ], function( key, boundle ) {
				var enableRadio = $(disabledoptions).filter( function() {
					return $(this).val() == key && boundle.qty > 0 ;
				} );
				enableRadio.prop('disabled', false);
				// enableRadio.closest('.chooseSize__label').removeClass('disabled');
				// if( enableRadio.closest('.chooseSize__label').hasClass('active') ) {
				// 	enableRadio.prop('checked', true);
				// }
				// enableRadio.trigger('refresh');
				disabledoptions = disabledoptions.not( enableRadio );
			} );
			disabledoptions.each( function() {
				if( $(this).prop('selected') ) {
					// $(this).prop('checked', false);
					// $(this).closest('.chooseSize__label').addClass('active');
					select.val('');
				}
				$(this).prop('disabled', true);
			} );
			select.trigger('refresh');
		}
	}
	if( $(this).data('key') == 1 ) { // ������� ������
	}
	var select0 = $('select[name=option_0]', wrap);
	var select1 = $('select[name=option_1]', wrap);
	var hiddenInput = $('input[name="id[1]"]', wrap);
	if( select0.val() != '' && select1.val() != '' ) {
		var bundle = bundles[ select0.val() ][ select1.val() ];
		hiddenInput.val( bundle.id ).trigger('change');
	} else {
		hiddenInput.val('');
	}
});
$(document).on('change', '.j-change-add-trigger', function(e) {
	$(this).closest('.product').find('.j-add-product').removeClass('mfp-hide').next('.j-add-product-done').addClass('mfp-hide').next('.j-same-products-link').addClass('mfp-hide');
});
$(document).on('change', '.j-bundles input', function(e) { // TODO ����������!!!
	var wrap = $(this).closest('.j-bundles');
	var bundles = wrap.data('bundles');
	if( $(this).data('key') == 0 ) { // ������� �����
		var disabledRadio = $('[name=option_1]', wrap);
		$.each( bundles[ $(this).val() ], function( key, boundle ) {

			var enableRadio = $(disabledRadio).filter( function() {
				return $(this).val() == key && boundle.qty > 0 ;
			} );
			enableRadio.prop('disabled', false);
			enableRadio.closest('.chooseSize__label').removeClass('disabled');
			if( enableRadio.closest('.chooseSize__label').hasClass('active') ) {
				enableRadio.prop('checked', true);
			}
			enableRadio.trigger('refresh');
			disabledRadio = disabledRadio.not( enableRadio );
		} );
		disabledRadio.each( function() {
			if( $(this).prop('checked') ) {
				$(this).prop('checked', false);
				$(this).closest('.chooseSize__label').addClass('active');
			}
			$(this).prop('disabled', true).prop('checked', false).trigger('refresh');
			$(this).closest('.chooseSize__label').addClass('disabled');
		} );
	}
});


$('#checkout_get_mail').on('click', '.js-submit', function(){
	var input = $('#checkout_get_mail').find('input[name=email]');
	if( input.val() == '' ) {
		input.addClass('error');
		return false;
	} else {
		input.removeClass('error');
		$.ajax({
			url: '/checkout_success.php',
			data : {
				email : input.val(),
				action : 'set_email',
				ajax : 3,
			},
			dataType: 'json',
			success: function(msg){
				if( msg['error'] != undefined && msg.error == 0 ) {
					$('#checkout_get_mail').find('.mfp-close').trigger('click');
				} else {
					input.val('');
					input.addClass('error');
				}
			}
		});
	}
});


$(document).on({
	'focusin': function(e) {
		$(this).addClass('edited');
	},
	'focusout': function(e) {
		if ( $(this).val() == '' ) {
			$(this).removeClass('edited');
		}
	}
}, 'input[name=email]', '#exit-popup-viewed, #exit-popup');

$(document).on('click', '.placeholder', '#exit-popup-viewed, #exit-popup', function(){
	$(this).prev('input[name=email]').addClass('edited').focus();
});

$(document).on('click', '#call_me_message-popup', function(){
	if($(this).hasClass('btn')){
		var phone = ($(this).data('new_phone') == undefined ? '' : '7' + $(this).data('new_phone').replace(/[^0-9]/g, '').substr(1) );
		$.ajax({
			url:'/account_history_info.php?action=callme&no_redirect=1&order_id='+$(this).data('ordersId')+'&new_phone='+phone,
			context: this,
			success: function(){
				$(this).html('<b>��� ������ �� ������ ������. �� ���������� � ������� 30 ���.</b>').removeClass('btn').addClass('mess');
				$(this).closest('.popupWindow').find('#change_number').css('display', 'none');
			}
		});
	}
});

$(document).on('click', '#change_number', function(e){
	$(this).hide();
	$('.change_number_div').css('display', 'flex');
});

$(document).on('click', '.change_number', function(e){
	var popup = $(this).closest('.popupWindow');
	var callbackbtn = popup.find('#call_me_message-popup');
	var new_phone = popup.find('input[name=phone]').val();
	callbackbtn.data('new_phone',new_phone);
	callbackbtn.html('��������� ��� �� ' + new_phone);
	popup.find('.change_number_div').css('display', 'none');
	$('#change_number').show();
});

$(document).on('click', '.j-to-reviews-link', function(){
	$('html, body').animate({ scrollTop: $('#productsReviewsBlock').offset().top - 50 });
});

hideBreadCrumbs();
$(window).resize(function() {
	hideBreadCrumbs();
});
function hideBreadCrumbs() {
	var breadcrumbs = $('.breadCrumbs-list');
	breadcrumbs.find('.bread-toggler').remove();
	breadcrumbs.prepend('<li class="bread-toggler"><a href="#">...</a></li>');

	var breadToggler = breadcrumbs.find('.bread-toggler');

	breadcrumbs.find('>li').hide().last().show();


	var breadcrumbsHeight = breadcrumbs.height();
	breadToggler.show();
	$.each(breadcrumbs.find('>li:hidden').get().reverse(), function(k, v) {
		$(v).show();
		if( breadcrumbs.height() > breadcrumbsHeight ) {
			$(v).hide();
			return false;
		}
	});
	if( breadcrumbs.find('>li:hidden').length == 0 ) {
		breadToggler.remove();
	}
	breadcrumbs.parent().css('overflow', 'visible');
}
$('.breadCrumbs-list').on('click', '.bread-toggler', function( e ) {
	e.preventDefault();
	$(this).closest('.breadCrumbs-list').css('height', 'auto').find('>li').show();
	$(this).remove();
});

if ( viewport().width <= 575 ) {
	$('.fullWidthPhotos .catalog-item-4:nth-child(7n) .seventh-image').each(function(){
		$(this).attr('src', $(this).data('src'));
	});
}

$(document).on('click', '.j-load-more-product-reviews', function(e) {
	e.preventDefault();
	var btn = $(this);
	if( btn.data('loading') == 1 ) {
		return;
	}
	var wrapper = btn.closest('.product-reviews__wrapper');
	var listEl = $('.reviews-list', wrapper);
	btn.data('loading', 1).addClass('loading');
	product.getMoreReviews( {
		products_id: btn.data('products-id'),
		limit:       btn.data('limit'),
		offset:      btn.data('from'),
		type:        btn.data('type'),
	}, function( msg ) {
		var el = listEl.append( msg.data );
		btn.data('from', btn.data('from') + btn.data('limit'));
		btn.data('loading', 0).removeClass('loading');
		if( btn.data('from') >= msg.reviewsCount ) {
			btn.hide();
		}
		var height_footer = $('footer').height();
		var height_bottomSections = $('.bottomSections').height();
		var height_bottom = height_footer + height_bottomSections;
		var viewport_wid = viewport().width;
		if (viewport_wid >= 768) {
			destroySticky();
			initSticky(height_bottom);
		}
	} );
});

$(document).on('click', '.j-more-cat-reviews', function(){
	if ( $(this).hasClass('load') ) return;
	var elem = $(this);
	var elData = $(this).data();
	elData['ajax'] = 3;
	elData['action'] = 'moreReviews';
	elem.addClass('load');
	$.ajax({
		url: elData.reviewUrl,
		dataType: 'json',
		data: elData,
		success: function( msg ) {
			if ( msg['reviewsCount'] > 0 ) {
				$('.j-cat-reviews-list').append( msg['reviewsHTML'] );
				if ( elData['reviewFrom'] + msg['reviewsCount'] >= elData['reviewCount'] ) {
					elem.addClass('hidden');
				}
				elem.data( 'review-from', elData['reviewFrom'] + msg['reviewsCount'] );
			}
			elem.removeClass('load');
		}
	})
});

function inWindow(elem) {
	if(!$(elem).length) return false;
	return (($(elem).offset().top + $(elem).height()) <= ($(window).scrollTop() + $(window).height()));
}
function triggrtCatalogABtest(){
	if( inWindow( '.j-cat-reviews-event' ) !== false ){
		if( window.PROSKTR.abtests.AB_Catalog_AddReviewsUnderListing_WideScreen != undefined && window.PROSKTR.abtests.AB_Catalog_AddReviewsUnderListing_WideScreen.sended == undefined ) {
			window.PROSKTR.abtests.AB_Catalog_AddReviewsUnderListing_WideScreen.sended = 1;
			eval( PROSKTR.abtests.AB_Catalog_AddReviewsUnderListing_WideScreen.code );
		}
	}
}
$( window ).on('scroll', triggrtCatalogABtest);


$(document).on('click', '.j-open-country-popup', function(e) {
	e.preventDefault();
	$.magnificPopup.open({
		items: {
			src: '#countries-popup',
			type: 'inline',
		},
	});
});
$(document).on('click', '.j-select-country-btn', function(e) {
	e.preventDefault();
	$(this).closest('form').trigger('submit');
});
$(document).on('submit', '.j-select-country-form', function(e) {
	e.preventDefault();
	var countrySelect = $('select[name=country]', this);
	setCountry(countrySelect.val());
});
$(document).on('change', '.j-select-country-form [name=country]', function(e) {
	var option = $('option[value=' + $(this).val() + ']', this);
	var currency = option.data('currency');
	var form = $(this).closest('form');
	$('.currency-text .value', form).text( currency );
});
function setCountry(id){
	$.ajax({
		url: '/ajax.php',
		dataType: 'json',
		data: {
			country: id,
			action: 'setCountry'
		},
		success: function(data) {
			window.location.reload();
		}
	});
}


$(document).on('click', '.j-show-search-form', function(e) {
	if( !$(this).closest('form').hasClass('opened') ) {
		e.preventDefault();
		$(this).closest('form').addClass('opened');
	}
});
$(document).on('click', '.header-navMobile .subNav-item .menu-opener', function(e) {
	$(this).closest('.subNav-item').toggleClass('opened');
	e.preventDefault();
});

function initLoginForm() {
	$('.j-get-pass').on('click', function(e){
		e.preventDefault();
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name="phone"]').val();
		let email_address = $form.find('input[name="phone"]').val();
		$('.j-form-error', $form).text('');
		$('#loginLoading').text('������ �����������...');
		$.ajax({
			url: "/ajax_customer.php?action=checkAndSendPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				login: email_address,
			},
			error: function(msg) {
				$button.removeClass('disabled');
				$('.j-form-error', $form).text(msg.err_mes);
				$('#loginLoading').text('');
			},
			success: function(msg) {
				$button.removeClass('disabled');
				$('#loginLoading').text('');
				if ( msg.result == 1 ) {
					$button.addClass('d-none');
					$('.j-code-holder, .j-login-btn, .j-reget-pass, .j-reget-pass-call', $form).removeClass('d-none');
				}
				$('.j-form-error', $form).text(msg.err_mes);
			}
		});
	})
	$('.j-reget-pass').on('click', function(e){
		e.preventDefault();
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name="phone"]').val();
		let email_address = $form.find('input[name="phone"]').val();
		$('.j-form-error', $form).text('');
		$.ajax({
			url: "/ajax_customer.php?action=resendNamePassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				login: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).text(msg.err_mes);
			},
			success: function(msg) {
				$('.j-form-error', $form).text(msg.err_mes);
			}
		});
    });
	$('.j-reget-pass-call').on('click', function(){
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name="phone"]').val();
		let email_address = $form.find('input[name="phone"]').val();
		$('.j-form-error', $form).html('');
		$.ajax({
			url: "/ajax_customer.php?action=recallWithPassCode&ajax=1",
			type: 'POST',
			dataType: 'json',
			data: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				login: email_address,
			},
			error: function(msg) {
				$('.j-form-error', $form).html(msg.err_mes);
			},
			success: function(msg) {
				$button.addClass('d-none');
				$('.j-form-error', $form).html(msg.err_mes);
			}
		});
	});
	$('.j-login-btn').on('click', function(e){
		e.preventDefault();
		if ( $(this).hasClass('disabled') ) return;
		$(this).addClass('disabled');
		let $form = $(this).closest('form');
		let $button = $(this);
		let phone = $form.find('input[name="phone"]').val();
		let email_address = $form.find('input[name="email_address"]').val();
		let passCode = $form.find('input[name="passCode"]').val();
		$('.j-form-error', $form).text('');
		customer.loginByPassCode({
			customerInfo: {
				phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
				passCode: passCode,
				login: email_address
			},
			error: function(msg) {
				$button.removeClass('disabled');
				$('.j-form-error', $form).text(msg.err_mes);
			},
			success: function(msg) {
				$.magnificPopup.close();
				if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
				if( PROSKTR.content == 'login' ) {
					window.location.href = '/account.php';
				}
			}
		});
	});
}

initLoginForm();

function loadOmni(element) {
	!function(e,o){!window.omni?window.omni=[]:'';window.omni.push(o);o.g_config={widget_id:"3066-9njc88w0"}; o.email_widget=o.email_widget||{};var w=o.email_widget;w.readyQueue=[];o.config=function(e){ this.g_config.user=e};w.ready=function(e){this.readyQueue.push(e)};var r=e.getElementsByTagName("script")[0];c=e.createElement("script");c.type="text/javascript",c.async=!0;c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";r.parentNode.insertBefore(c,r)}(document,[]);
	window.ChatraGroupID = 'NYhvRrTDQTdXgS9qs';
	let interv = setInterval(function() {
		if ( typeof OmniWidgetApi != 'undefined' && $('[id^=omni_widget_iframe]').length ) {
			$('[id^=omni_widget_iframe]').on('load', function(){
				OmniWidgetApi.call('open');
				$(element).hide();
				clearInterval(interv);
			});
		}
	}, 300);
}

$('#omni-pseudo-btn').on('click', function(){
	$(this).addClass('loading');
	loadOmni(this);
});

$('.j-main-page-slider-3').slick({
	arrows: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	dots: false,
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}
	]
});
$(function(){
	initProductPage();
	initKamenMenu();
});

function initProductPage(){
	var isProduvtPage = $('section.product').length;

	if(!isProduvtPage){
		return false;
	}
	initChangeCity();

	function initChangeCity(){
		$('#products_buttom_info_delivery').ajaxForm({
			beforeSubmit: function() {
				var cityName = $('.j-info-shop-city-input').val().split('(')[0].trim();

				$('.j-delivery-city').text(cityName);
				$('#info_delivery_res').html('<img class="preloader" src="/templates/dom/public/img/preloader.gif">');
				$('#select-delivery-city-popup .mfp-close').trigger('click');
			},
			success: function (data) {
				var hasDeliveryData = typeof data.content !== "undefined" && typeof data.content[0];

				if(!hasDeliveryData){
					return false;
				}
				$('#info_delivery_res').css('display', 'block');
				var more = $('.js-delivery-block');
				more.css('display', 'block');
				var form = $('#products_buttom_info_delivery');
				more.attr("href", "/info/delivery?city=" + form.find('input[name=city]').val() + "&zone_id=" + form.find('input[name=zone_id]').val());
				$('#info_delivery_res').html(data.content[0]);
			}
		}).submit();
	}
}

function initKamenMenu(){
	$.post('/ajax.php?action=get_kamen_menu_for_dom').done(function(data) {
		if(typeof data.kamen_menu === "undefined"){
			return true;
		}
		var kamenMenu = '',
			kamenMenuMobile = '';

		if(typeof data.kamen_menu['�������'] !== "undefined"){
			kamenMenu += $.render[ 'kamen_menu' ].render( data.kamen_menu['�������'] );
			kamenMenuMobile += $.render[ 'kamen_menu_mobile' ].render( data.kamen_menu['�������'] );
		}
		if(typeof data.kamen_menu['�������'] !== "undefined"){
			kamenMenu += $.render[ 'kamen_menu' ].render( data.kamen_menu['�������'] );
			kamenMenuMobile += $.render[ 'kamen_menu_mobile' ].render( data.kamen_menu['�������'] );
		}
		if(typeof data.kamen_menu['������'] !== "undefined"){
			kamenMenu += $.render[ 'kamen_menu' ].render( data.kamen_menu['������'] );
			kamenMenuMobile += $.render[ 'kamen_menu_mobile' ].render( data.kamen_menu['������'] );
		}
		$('.js-kamen-menu').html(kamenMenu);
		$('.js-kamen-menu-mobile').html(kamenMenuMobile);
	});
}

$('#mindbox-login').on('submit', 'form', function(e){
	e.preventDefault();
});
$('#createAccountFormMindboxHolder').on('submit', 'form', function(e){
	e.preventDefault();
});

function MindboxFormSubmit( id ) {
	let hasEmpty = false;
	let $form = $('#' + id);
	let $btn = $('.btn-submit', $form);

	if ( $btn.hasClass('disabled') ) return;
	$btn.addClass('disabled').addClass('btn-loading');

	$('input[type=text]', $form).each(function(){
		if ( $(this).val() == '' ) {
			$(this).addClass('error');
			hasEmpty = true;
		}
	});
	if ( hasEmpty ) {
		$btn.removeClass('disabled').removeClass('btn-loading');
		return;
	}
	$.ajax({
		url: '/ajax_mindbox.php',
		type: 'POST',
		data: $form.serializeArray(),
		dataType: 'json',
		context: this,
		success: function( msg ) {
			$('#mindbox-login').html(msg.html);
			if ( msg.login ) {
				$.magnificPopup.close();
				customer.afterLogin(null, msg, null);
				shoppingCart.reloadPopup();
				if ( PROSKTR.content == 'shopping_cart' ) {
					window.location.reload();
				} else {
					window.location.href = '/account.php';
				}
			}
		}
	});
}

function initMindboxRegistration() {
	let $form = $('.j-mindbox-registration-from');

	$form.on('submit', function(e) {
		e.preventDefault();
		if ( $form.hasClass('form-valid-error') ) {
			return;
		}

		let data = $form.serializeArray();
		let code = $form.find('input[name=code]').val();
		$form.find('.j-reg-result').html('');
		data.push({name: 'ajax', value: '3'});
		$.ajax({
			type: 'POST',
			data: data,
			dataType: 'json',
			context: this,
			success: function( msg ) {
				if ( msg.done ) {
					if ( code == '' ) {
						$form.find('.j-code-row').removeClass('d-none');
					} else {
						$form.html(msg.message);
					}
				}
				if ( code == '' ) {
					$form.find('.j-reg-result').html(msg.message);
				} else {
					$form.find('.j-code-row').find('.alidation-description').html(msg.message);
				}
			}
		});
	});
}

initMindboxRegistration();

ajax_fetch_coupons('product');

$('.j-send-email-confirmation').on('click', function(e) {
	e.preventDefault();
	$.ajax({
		url: '/ajax_customer.php?ajax=1',
		type: 'POST',
		data: {'action': 'sendEmailConfirmation'},
		dataType: 'json',
		context: this,
		success: function( msg ) {
			if ( msg.done == 'done' ) {
				$('.j-send-email-confirmation').addClass('d-none');
				$('.j-send-email-done').removeClass('d-none');
			}
		}
	});
});
